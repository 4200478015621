import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Box, ListItemText } from '@mui/material';
import { deleteNotification, updateNotification } from './store/dataSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

function NotificationCard(props) {
  const dispatch = useDispatch();
  const { item, className, setPage } = props;
  const variant = item?.variant || '';
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
    setPage(1);
  };

  const handleRead = () => {
    const request = {
      notification_id: item?.id,
      field: 'is_read',
      value: item?.is_read === 0 ? 1 : 0,
    };
    dispatch(updateNotification(request));
    handleActionClose();
  };

  const handleDelete = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const request = {
      id: [item?.id],
      driver_id: item?.user_id,
    };
    dispatch(deleteNotification(request));
    handleActionClose();
    if (props.onClose) {
      props.onClose(item.id);
    }
  };

  function renderContent(item) {
    const content = (
      <div className="flex flex-col flex-auto">
        {item.message && (
          <Typography
            className={`${item?.is_read === 0 ? 'font-bold' : ''} line-clamp-2`}
          >
            {item.message}
          </Typography>
        )}
        {item?.trip?.start_location && (
          <div className="line-clamp-2 text-sm">
            {item?.trip?.start_location}
          </div>
        )}
        {item?.trip?.end_location && (
          <div className="line-clamp-2 text-sm">{item?.trip?.end_location}</div>
        )}

        {item?.trip?.start_location && (
          <div className="line-clamp-2 text-sm">
            {item?.trip?.start_location},
          </div>
        )}
        {item?.trip?.end_location && (
          <div className="line-clamp-2 text-sm">{item?.trip?.end_location}</div>
        )}
        {item?.status === 1 && (
          <div className="bg-green text-white text-xs font-semibold px-14 py-1 mt-4 rounded-full w-fit">
            Accepted
          </div>
        )}
        {item?.status === 0 && (
          <div className="bg-red text-white text-xs font-semibold px-14 py-1 mt-4 rounded-full w-fit">
            Rejected
          </div>
        )}
        {item?.status === null && (
          <div className="bg-yellow-800 text-white text-xs font-semibold px-14 py-1 mt-4 rounded-full w-fit">
            Pending
          </div>
        )}
      </div>
    );
    return content;
  }

  function getNavigationLink(item) {
    let link = '';
    if (item?.trip_id) {
      link += `/map/${item?.trip_id}`;
    } else {
      link += '/';
    }
    return link;
  }

  function checkForNavigationPoint(item) {
    let navigationPoint = false;
    if (item?.trip_id) {
      navigationPoint = true;
    }
    return navigationPoint;
  }

  const handleCardClick = () => {
    // Implement your card click logic here
    // This function will be called when the card is clicked
    if (checkForNavigationPoint(item)) {
      history.push(`${getNavigationLink(item)}`);
    }
  };

  return (
    <Card
      className={clsx(
        'flex items-center relative w-full rounded-16 p-14 min-h-64 shadow space-x-8',
        item?.is_read === 1 && 'opacity-60',
        className
      )}
      elevation={0}
      onClick={handleCardClick} // Set up the card click handler
      component={checkForNavigationPoint(item) ? NavLinkAdapter : 'div'}
      to={getNavigationLink(item)}
      role={'button'}
    >
      <Box
        sx={{ backgroundColor: 'background.default' }}
        className="flex shrink-0 items-center justify-center w-32 h-32 mr-12 rounded-full"
      >
        {item?.trip_id && (
          <FuseSvgIcon className="opacity-75" color="inherit">
            heroicons-solid:truck
          </FuseSvgIcon>
        )}
      </Box>
      {/* {item.image && (
        <img
          className="shrink-0 w-32 h-32 mr-12 rounded-full overflow-hidden object-cover object-center"
          src={item.image}
          alt="Notification"
        />
      )} */}

      <div className="flex flex-col flex-auto">
        {renderContent(item)}
        {item?.createdAt && (
          <Typography
            className="mt-8 text-sm leading-none "
            color="text.secondary"
          >
            {formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })}
          </Typography>
        )}
      </div>

      {/* <IconButton
        className="top-0 right-0 absolute p-8"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => {
          event.stopPropagation(); // Prevent card click event
          handleClick(event); // Call the handleClick function for the icon
        }}
      >
        <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
      </IconButton> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleActionClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleRead}>
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={`Mark as ${item?.is_read === 0 ? 'read' : 'unread'}`}
          />
        </MenuItem>

        <MenuItem onClick={handleDelete}>
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      {/* <IconButton
        disableRipple
        className="top-0 right-0 absolute p-8"
        color="inherit"
        size="small"
        onClick={handleClose}
      >
        <FuseSvgIcon size={12} className="opacity-75" color="inherit">
          heroicons-solid:x
        </FuseSvgIcon>
      </IconButton> */}
      {item.children}
    </Card>
  );
}

export default NotificationCard;
