import i18next from 'i18next';
import { lazy } from 'react';

import ar from './i18n/ar';
import de from './i18n/de';
import en from './i18n/en';

i18next.addResourceBundle('en', 'vehiclesPage', en);
i18next.addResourceBundle('de', 'vehiclesPage', de);
i18next.addResourceBundle('ar', 'vehiclesPage', ar);

// Declare a lazy-loaded component Vehicles, which will be dynamically loaded when needed
const Vehicles = lazy(() => import('./list/Vehicles'));
const Vehicle = lazy(() => import('./add-edit/Vehicle'));

// VehiclesConfig object containing the configuration for the Vehicles page
const VehiclesConfig = {
  // settings object for additional page settings (e.g., layout settings)
  settings: {
    layout: {}, // You can add layout settings here if needed
  },

  // routes array containing the route configurations for the Vehicles page
  routes: [
    {
      path: '/vehicles',
      element: <Vehicles />, // The React element to be rendered for the Vehicles page
    },
    {
      path: 'vehicles/:vehicleId/*',
      element: <Vehicle />,
    },
  ],
};

export default VehiclesConfig;
