import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import withReducer from 'app/store/withReducer';
import { useSnackbar } from 'notistack';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NotificationCard from './NotificationCard';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  // addNotification,
  dismissAll,
  // deleteNotification,
  getNotifications,
  getTripNotifications,
  saveToken,
  selectNotificationCount,
  selectNotifications,
  selectRefetchNotifications,
} from './store/dataSlice';
import reducer from './store';
import {
  closeNotificationPanel,
  selectNotificationPanelState,
  toggleNotificationPanel,
} from './store/stateSlice';
import {
  fetchToken,
  onMessageListener,
  requestPermission,
} from './firebaseInitialize';
import { FormLabel, Switch } from '@mui/material';
import FuseLoading from '@fuse/core/FuseLoading';
import { useIntersectionObserver } from '@fuse/hooks';

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.default,
    width: 420,
  },
}));

const filterTypes = [
  { label: 'Trips', value: 1 },
  // { label: 'Shipments', value: 2 },
];

const PAGE_SIZE = 10;

function NotificationPanel(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const state = useSelector(selectNotificationPanelState);
  // const state = true;
  const notificationList = useSelector(selectNotifications);
  const refetchNotifications = useSelector(selectRefetchNotifications);

  const [selectedFilter, setSelectedFilter] = useState(0);
  const [unreadCheck, setUnreadCheck] = useState(false);
  const [notifications, setNotifications] = useState(notificationList);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const count = useSelector(selectNotificationCount);
  const user = JSON.parse(localStorage.getItem('user'));
  const userId = user.id ?? null;
  const totalPage = Math.ceil(count / PAGE_SIZE);

  // Callback function to load more notifications when the "Load More" button intersects the viewport
  const handleIntersection = () => {
    if (page < totalPage) {
      setPage((prev) => prev + 1);
    } else {
      setIsIntersecting(false);
    }
  };
  function handleClose() {
    dispatch(closeNotificationPanel());
  }

  function handleDismiss(id) {}

  function handleDismissAll() {
    dispatch(dismissAll());
  }
  const { targetRef, isIntersecting, setIsIntersecting } =
    useIntersectionObserver(handleIntersection);

  useEffect(() => {
    isIntersecting && setIsIntersecting(false);
  }, [page]);

  useEffect(() => {
    setLoading(true);
    /*    Get Notifications from db    */
    let request = {
      page,
      pageSize: PAGE_SIZE,
      sortLabel: 'id',
      sortOrder: 'DESC',
      status: 1,
      // user_id: userId,
    };

    if (unreadCheck) {
      request['is_read'] = false;
    }

    setIsIntersecting(false);

    dispatch(getNotifications(request)).then((res) => {
      if (page === 1) {
        setNotifications(res.payload);
      } else {
        setNotifications((prev) => [...prev, ...res?.payload]);
      }
      setLoading(false);
    });
  }, [dispatch, page, unreadCheck, refetchNotifications, selectedFilter]);

  useEffect(() => {
    setNotifications(notificationList);
  }, []);

  // useEffect(() => {
  //   // fetch trip notifications
  //   if (selectedFilter === 1) {
  //     dispatch(getTripNotifications(userId));
  //   }
  // }, [dispatch, selectedFilter, userId, unreadCheck]);

  useEffect(() => {
    if (state) {
      dispatch(closeNotificationPanel());
    }
    // eslint-disable-next-line
  }, [location, dispatch]);

  useEffect(() => {
    setIsIntersecting(false);
    setPage(1);
  }, [unreadCheck]);

  // to save device token fetched from fcm when component mounts
  useEffect(() => {
    (async () => {
      requestPermission();
      const token = await fetchToken();
      if (token) {
        const request = {
          user_id: userId,
          device_token: token,
          device_type: 'WEB',
        };
        dispatch(saveToken(request));
      }
    })();
  }, []);

  onMessageListener()
    .then((payload) => {
      enqueueSnackbar(payload.notification.title, {
        variant: 'success'
      })
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <StyledSwipeableDrawer
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >
      <IconButton
        className="m-4 absolute top-0 right-0 z-999"
        onClick={handleClose}
        size="large"
      >
        <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
      </IconButton>
      {notifications.length > 0 ? (
        <FuseScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="flex justify-between items-end py-16 mt-20">
              <Typography className="text-18 font-semibold leading-none">
                Notifications
              </Typography>
              <Link
                component="button"
                className="text-sm"
                color={'secondary'}
                onClick={() => {}}
              >
                Mark all as read
              </Link>
            </div>
            <div className="pt-4 mb-20 flex flex-row gap-20">
              <FormControl
                className="flex w-full sm:w-136"
                size="small"
                variant="outlined"
              >
                <InputLabel id="role-select-label">Type</InputLabel>
                <Select
                  labelId="notification-select-label"
                  id="notification-type"
                  label="Type"
                  autoWidth
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  MenuProps={{
                    style: {
                      maxHeight: '30ch',
                      width: '20ch',
                    },
                  }}
                >
                  <MenuItem dense value={0}>
                    <em> All </em>
                  </MenuItem>
                  {filterTypes.map((type) => (
                    <MenuItem dense value={type.value} key={type.label}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="flex items-center">
                <FormControlLabel
                  className="mt-8 text-xs"
                  label="Only show unread"
                  labelPlacement="end"
                  control={
                    <Switch
                      onChange={(ev) => {
                        setUnreadCheck(ev.target.checked);
                        setPage(1);
                      }}
                      checked={unreadCheck}
                      name="show_unread"
                      size="small"
                    />
                  }
                />
              </div>
            </div>
            {!loading &&
              notifications?.map((item) => (
                <NotificationCard
                  key={item.id}
                  className="mb-16"
                  item={item}
                  onClose={handleDismiss}
                  setPage={setPage}
                />
              ))}
            {loading && <FuseLoading />}
            {!isIntersecting && !loading && (
              <div
                ref={targetRef}
                style={{
                  visibility: isIntersecting ? 'visible' : 'hidden',
                }}
              ></div>
            )}
          </div>
        </FuseScrollbars>
      ) : (
        <div className="flex flex-1 items-center justify-center p-16">
          <Typography className="text-24 text-center" color="text.secondary">
            There are no notifications for now.
          </Typography>
        </div>
      )}
    </StyledSwipeableDrawer>
  );
}

export default withReducer(
  'notificationPanel',
  reducer
)(memo(NotificationPanel));
