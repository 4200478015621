import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import FuseUtils from '@fuse/utils';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTrip, selectTrips, selectTripsCount } from '../../store/mapSlice';
import TripListItem from './TripListItem';
import { useIntersectionObserver } from '@fuse/hooks';
import Skeleton from '@mui/material/Skeleton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  '&.active': {
    backgroundColor: theme.palette.background.default,
  },
}));

const SkeletonListItem = () => (
  <StyledListItem button className="px-14 py-8 min-h-60">
    <div className="flex flex-col">
      <Skeleton variant="text" animation="wave" width={150} />
      <Skeleton variant="text" animation="wave" width={100} />
      <div className="flex items-center justify-center">
        <div className="mt-8 mr-12">
          <Skeleton
            variant="circular"
            animation="wave"
            width={20}
            height={20}
          />
        </div>
        <div className="flex flex-col">
          <Skeleton variant="text" animation="wave" width={180} />
          <Skeleton variant="text" animation="wave" width={150} />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="mt-10 mr-12">
          <Skeleton
            variant="circular"
            animation="wave"
            width={20}
            height={20}
          />
        </div>
        <div className="flex flex-col">
          <Skeleton variant="text" animation="wave" width={180} />
          <Skeleton variant="text" animation="wave" width={150} />
        </div>
      </div>
    </div>
  </StyledListItem>
);

function TripList(props) {
  const { t } = useTranslation('mapPage');
  const dispatch = useDispatch();
  const trips = useSelector(selectTrips);
  const [searchText, setSearchText] = useState('');
  const count = useSelector(selectTripsCount); // Selecting 'count' from the Redux store

  // Use the custom hook to observe the "Load More" button
  const { targetRef, isIntersecting } = useIntersectionObserver(
    props?.handleIntersection
  );

  function handleSearchText(event) {
    setSearchText(event.target.value);
  }

  return (
    <div className="flex flex-col flex-auto h-full">
      <Box
        className="py-12 px-14 border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        {useMemo(
          () => (
            <Paper className="flex p-4 items-center w-full px-16 py-4 border-1 h-36 rounded-full shadow-none">
              <FuseSvgIcon color="action" size={20}>
                heroicons-solid:search
              </FuseSvgIcon>
              <Input
                className="flex flex-1 px-8"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  'aria-label': 'Search',
                }}
                onChange={handleSearchText}
              />
            </Paper>
          ),
          [searchText]
        )}
      </Box>

      <FuseScrollbars className="overflow-y-auto flex-1">
        <List className="w-full">
          {useMemo(() => {
            function getFilteredArray(arr, _searchText) {
              if (_searchText.length === 0) {
                return arr;
              }
              return FuseUtils.filterArrayByString(arr, _searchText);
            }

            const filteredTripList = getFilteredArray([...trips], searchText);

            const container = {
              show: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            };

            const item = {
              hidden: { opacity: 0, y: 20 },
              show: { opacity: 1, y: 0 },
            };

            return (
              <motion.div
                className="flex flex-col shrink-0"
                variants={container}
                initial="hidden"
                animate="show"
              >
                {filteredTripList.length > 0 && (
                  <motion.div variants={item}>
                    <Typography
                      className="font-medium text-20 px-14 py-6"
                      color="secondary.main"
                    >
                      {t('TRIPS')}
                    </Typography>
                  </motion.div>
                )}
                {filteredTripList.map((trip, index) => (
                  <motion.div variants={item} key={trip.id}>
                    <div
                      className={clsx(
                        filteredTripList.length !== index + 1 && 'border-b-1'
                      )}
                    >
                      <TripListItem
                        trip={trip}
                        onTripClick={(tripId) => dispatch(getTrip(tripId))}
                      />
                    </div>
                  </motion.div>
                ))}
                {
                  <div
                    ref={targetRef}
                    style={{
                      visibility: isIntersecting ? 'visible' : 'hidden',
                    }}
                  >
                    {/* Use the SkeletonListItem component here */}
                    <SkeletonListItem />
                  </div>
                }
              </motion.div>
            );
          }, [trips, searchText, dispatch])}
        </List>
      </FuseScrollbars>
    </div>
  );
}

export default TripList;
