import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ACTIVITY_TYPES } from 'src/app/main/utils/Constants';

const ActivitySelect = forwardRef((props, ref) => {
  const { t } = useTranslation('mapPage');
  const {
    fieldName,
    label,
    isFocused,
    isEdit,
    value,
    onChange,
    className,
    setFocusedField,
    handleEditField,
    handleCancelEdit,
  } = props;
  const dispatch = useDispatch();

  // Get the activityTypes array from the state
  const activityTypes = ACTIVITY_TYPES;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="flex flex-col">
      <FormControl required fullWidth className={className} size="small">
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-activityType"
          id="activityType-select"
          value={value}
          label={label}
          onChange={handleChange}
          onFocus={() => setFocusedField(fieldName)}
          ref={ref}
          classes={{ select: 'flex items-center space-x-12' }}
        >
          {activityTypes.map((activityType) => (
            <MenuItem value={activityType} key={activityType} className="space-x-12">
              <span>{activityType}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isFocused && isEdit ? (
        <div className="flex justify-end mt-2">
          <div className="border p-1 rounded">
            <FuseSvgIcon
              className="cursor-pointer text-gray-500"
              onClick={() => {
                handleEditField(fieldName, value);
              }}
            >
              heroicons-outline:check
            </FuseSvgIcon>
          </div>
          <div className="border rounded ml-2">
            <FuseSvgIcon
              className="cursor-pointer text-gray-500"
              onClick={() => {
                handleCancelEdit(fieldName);
              }}
            >
              heroicons-outline:x
            </FuseSvgIcon>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default ActivitySelect;
