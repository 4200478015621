import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LocationSelect from 'src/app/shared-components/LocationSelect';
import * as yup from 'yup';
import { MapContext } from '../Map';
import {
  selectTrip,
  selectTripCheckpoints,
  setTripCheckpoints,
} from '../store/mapSlice';
import ActivitySelect from './ActivitySelect';
import EditableField from './EditableField';
import { get } from 'lodash';
import { getLocationDisplayValue } from 'src/app/services/commonService';

function CheckpointSidebar(props) {
  const { t } = useTranslation('mapPage');
  const { setCheckpointSidebarOpen } = useContext(MapContext);
  const dispatch = useDispatch();
  const trip = useSelector(selectTrip);
  const isEdit = false; // Always false for now
  const checkpoints = useSelector(selectTripCheckpoints);

  // Modify the validation schema dynamically for add/edit
  const schema = yup.object().shape({
    activity_type: yup.string().required('You must enter Fuel Type'),
    title: yup.string().when('activity_type', {
      is: (value) => value === 'Other',
      then: (schema) => schema.required('You must enter Title'),
      otherwise: (schema) => schema,
    }),
    checkpoint_location: yup.object().required('You must enter Location'),
    comment: yup.string(),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      activity_type: '',
      title: '',
      checkpoint_location: null,
      comment: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    setValue,
    reset,
    watch,
    handleSubmit,
    control,
    onChange,
    formState,
    getValues,
  } = methods;
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const [editedFields, setEditedFields] = useState({});
  // State to keep track of the focused field
  const [focusedField, setFocusedField] = useState(null);

  const handleEditField = (fieldName, value) => {
    const updatedValue = getValues()[fieldName];
    if (String(updatedValue).trim() === '') {
      return;
    }

    let request = {
      field: fieldName,
      value: updatedValue,
      trip_id: trip?.id,
    };

    setEditedFields((prevEditedFields) => ({
      ...prevEditedFields,
      [fieldName]: updatedValue,
    }));

    // dispatch();
    setFocusedField(null);
  };

  const handleEnterKeyPress = (event, fieldName, fieldValue) => {
    if (event.key === 'Enter' && isEdit && focusedField === fieldName) {
      handleEditField(fieldName, fieldValue);
    }
  };

  const handleCancelEdit = (fieldName) => {
    setValue(fieldName, editedFields[fieldName]); // Reset the input field to its default edit value
    setFocusedField(null);
  };

  const handleInput = (e) => {
    const keyValue = e.key;

    // Allow only numeric characters, spaces, slashes, and hyphens
    const allowedCharacters = /^[0-9\s/-]+$/;

    if (!allowedCharacters.test(keyValue)) {
      e.preventDefault();
    }
  };

  // useEffect(() => {
  //   reset(trip);
  // }, [reset, trip]);

  function onSubmit(data) {
    // Create a new item to add before the end location
    const newItem = {
      ...data,
      type: 'stop',
      icon: 'heroicons-solid:stop',
      date: '', // Replace with your content
      desc: `${getLocationDisplayValue(data?.checkpoint_location)}`,
      place: getLocationDisplayValue(data?.checkpoint_location),
      location: {
        lat: parseFloat(data?.checkpoint_location?.position?.lat),
        lng: parseFloat(data?.checkpoint_location?.position?.lon),
      },
    };

    // Find the index of the end location in checkpoints
    let test = [...checkpoints];
    // Find the index of the last item in checkpoints
    const endIndex = checkpoints.length - 1;

    // Insert the new item before the end location
    test.splice(endIndex, 0, newItem);
    dispatch(setTripCheckpoints(test));
  }

  if (_.isEmpty(form)) {
    return null;
  }

  return (
    <div className="flex flex-col flex-auto h-full">
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <Toolbar className="flex items-center px-24 border-b-1">
          <IconButton onClick={() => setCheckpointSidebarOpen(false)}>
            <FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>
          </IconButton>
          <Typography className="px-8 font-semibold text-20">
            {t('CHECKPOINT')}
          </Typography>
        </Toolbar>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} className="p-24">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12}>
            <Controller
              name="activity_type"
              control={control}
              render={({ field }) => (
                <ActivitySelect
                  fieldName="activity_type"
                  label={t('ACTIVITY_TYPE')}
                  {...field}
                  isFocused={focusedField === 'activity_type' ? true : false}
                  isEdit={isEdit}
                  setFocusedField={setFocusedField}
                  handleEditField={handleEditField}
                  handleCancelEdit={handleCancelEdit}
                />
              )}
            />
          </Grid>
          {form.activity_type === 'Other' && (
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <EditableField
                    field={field}
                    focusedField={focusedField}
                    errors={errors}
                    isEdit={isEdit}
                    label={t('TITLE')}
                    required={true}
                    setFocusedField={setFocusedField}
                    handleEditField={handleEditField}
                    handleCancelEdit={handleCancelEdit}
                    handleEnterKeyPress={handleEnterKeyPress}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="checkpoint_location"
              control={control}
              render={({ field: { value, onChange } }) => (
                <LocationSelect
                  fieldName="checkpoint_location"
                  label={t('LOCATION')}
                  value={value}
                  onChange={(newValue) => onChange(newValue)}
                  isFocused={
                    focusedField === 'checkpoint_location' ? true : false
                  }
                  isEdit={isEdit}
                  isCheckpoint={true}
                  setFocusedField={setFocusedField}
                  handleEditField={handleEditField}
                  handleCancelEdit={handleCancelEdit}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="comment"
              render={({ field }) => (
                <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                    <TextField
                      {...field}
                      size="small"
                      className={
                        focusedField === 'comment' || errors?.comment
                          ? ''
                          : 'mb-16'
                      }
                      label={t('COMMENT')}
                      type="text"
                      multiline
                      minRows={6}
                      maxRows={12}
                      error={!!errors.comment}
                      // helperText={errors?.comment?.message}
                      // Set the focused field to "role_id" when the field is focused
                      onFocus={() => setFocusedField('comment')}
                      variant="outlined"
                      autoComplete="off" // Add autoComplete="off" to disable autocomplete
                      fullWidth
                      onKeyDown={(event) => {
                        setFocusedField('comment');
                        handleEnterKeyPress(event, 'comment', form.comment);
                      }}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    <div>
                      {errors?.comment && (
                        <div className="flex items-center text-sm text-red">
                          <span className="p-4">{errors.comment.message}</span>
                        </div>
                      )}
                    </div>
                    {focusedField === 'comment' && isEdit ? (
                      <div className="flex justify-end mt-2">
                        <div className="border p-1 rounded">
                          <FuseSvgIcon
                            className="cursor-pointer text-gray"
                            onClick={() => {
                              handleEditField('comment', form.comment);
                            }}
                          >
                            heroicons-outline:check
                          </FuseSvgIcon>
                        </div>
                        <div className="border p-1 rounded ml-2">
                          <FuseSvgIcon
                            className="cursor-pointer text-gray"
                            onClick={() => {
                              handleCancelEdit('comment');
                            }}
                          >
                            heroicons-outline:x
                          </FuseSvgIcon>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            />
          </Grid>
        </Grid>
        <div className="flex items-center justify-end mt-32">
          <Button
            className="mx-8"
            size="small"
            onClick={() => setCheckpointSidebarOpen(false)}
          >
            {t('CANCEL')}
          </Button>
          <Button
            className="mx-8"
            variant="contained"
            color="secondary"
            size="small"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {t('SAVE')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CheckpointSidebar;
