const locale = {
  PAGE_TITLE: 'Drivers',
  ID: 'id',
  SEARCH_DRIVER: 'Search Driver',
  DRIVER_DETAIL: 'Driver Detail',
  ADD: 'Add',
  ADD_DRIVER: 'Add Driver',
  EDIT: 'Edit',
  EDIT_DRIVER: 'Edit Driver',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  ACTIVATE_DRIVER: 'Activate Driver',
  DEACTIVATE_DRIVER: 'Deactivate Driver',
  LOADING: 'Loading',
  SALUTATION: 'Salutation',
  USERNAME: 'Username',
  EMAIL: 'Email Address',
  ROLE: 'Role',
  BIRTH_DATE: 'Birth Date',
  PLACE_OF_BIRTH: 'Place of Birth',
  COUNTRY_OF_BIRTH: 'Country of Birth',
  PHONE_NUMBER: 'Phone Number',
  MOBILE_NUMBER: 'Mobile Number',
  MOBILE_BUSINESS_NUMBER: 'Mobile Buisness Number',
  STREET: 'Street',
  ADDRESS: 'Address',
  CITY: 'City',
  STATE: 'State',
  DISTRICT: 'District',
  LOCATION: 'Location',
  POSTAL_CODE: 'Postal Code',
  CHIP_NUMBER: 'Chip Number',
  TAX_IDENTIFICATION_NUMBER: 'Tax Identification Number',
  SOCIAL_SECURITY_NUMBER: 'Social Security Number',
  COMPANY_ENTRY: 'Company Entry',
  TRAIL_PERIOD: 'Trail Period',
  COMPANY_EXIT: 'Company Exit',
  STATUS: 'Status',
  CHANGE_DRIVER_STATUS: 'Change driver status',
  ACTIONS: 'Actions',
  NO_DOCUMENT: 'There is no document!',
  GO_TO_DRIVERS: 'Go to Drivers Page',
  No_DRIVER: 'There is no such driver!',
  DOWNLOAD: 'Download',
  ROWS_PER_PAGE: 'Rows per page:',
  OF: 'of',

  PASSWORD: 'Password',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  COUNTRY: 'Country',

  BASIC_INFO: 'Basic Info',
  ADDITIONAL_INFO: 'Additional Info',
  DOCUMENTS: 'Documents',
  DOCUMENT_TYPE: 'Document Type',
  DELETE: 'Delete',
  HIRING_INFO: 'Hiring info',

  LICENSE_NUMBER: 'License Number',
  FORKLIFT_LICENSE_NUMBER: 'Forklift License Number',
  FORKLIFT_LICENSE_EXPIRATION: 'Forklift License Expiration',
  FUEL_ID_CARD: 'Fuel Id Card',

  USERNAME_REQUIRED_ERR: 'You must enter a username',
  USERNAME_UNAVAILABLE_MSG:
    'Username is not available. Please choose a different one.',
  EMAIL_UNAVAILABLE_MSG: 'Email is already in use. Please use a different one.',

  STATUS_CHANGE_CONFIRMATION_MSG: 'Are a sure you want',
  DISABLED_DEACTIVATE_MSG: 'This driver is assigned for a trip',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
};

export default locale;
