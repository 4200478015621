const locale = {
  PAGE_TITLE: 'Vehicles',
  ID: 'id',
  SEARCH_VEHICLE: 'Search Vehicle',
  VEHICLE_DETAIL: 'Vehicle Detail',
  ADD: 'Add',
  ADD_VEHICLE: 'Add Vehicle',
  EDIT: 'Edit',
  EDIT_VEHICLE: 'Edit Vehicle',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  ACTIVATE_VEHICLE: 'Activate Vehicle',
  DEACTIVATE_VEHICLE: 'Deactivate Vehicle',
  DELETE_VEHICLE: 'Delete Vehicle',
  LOADING: 'Loading',
  SALUTATION: 'Salutation',
  NAME: 'Name',
  TYPE: 'Type',
  VIN: 'Vin',
  ACTIVE: 'Active',
  EMAIL: 'Email Address',
  ROLE: 'Role',
  VEHICLE_TYPES: 'Types',
  VEHICLE_STATUSES: 'Statuses',
  ALL: 'All',
  MANUFACTURER: 'Manufacturer',
  BRAND: 'Brand',
  TYPE: 'Type',
  FUEL_TYPE: 'Fuel Type',
  FUEL_TANK_CAPACITY: 'Fuel Tank Capacity',
  NUMBER_OF_CYLINDERS: 'Number of Cylinders',
  GEARS: 'Gears',
  DOORS: 'Doors',
  SEATS: 'Seats',
  TRANSMISSION: 'Transmission',
  COLOR: 'Color',
  COLOR_NUMBER: 'Color Number',
  ENGINE: 'Engine',
  TYPE_ID: 'Type ID',
  MODEL_ID: 'Model ID',
  VIN: 'VIN',
  IS_ACTIVE: 'Is Active',
  VEHICLE_ID: 'Vehicle ID',
  TOTAL_WEIGHT: 'Total Weight',
  OWN_WEIGHT: 'Own Weight',
  MAX_WEIGHT: 'Max Weight',
  MAX_VOLUME: 'Max Volume',
  MAX_WIDTH: 'Max Width',
  MAX_LENGTH: 'Max Length',
  MAX_HEIGHT: 'Max Height',
  WHEEL_SIZE: 'Wheel Size',
  CUSTOMER_FUEL_ID_CARD_NUMBER: 'Customer Fuel ID Card Number',
  REGISTRATION_DATE: 'Registration Date',
  SIGNING_OFF: 'Signing Off',
  LOCATION: 'Location',
  PALLET_LOAD: 'Pallet Load',
  LIFT_PAYLOAD: 'Lift Payload',
  INSIDE_DIMENSION_L: 'Inside Dimension L',
  INSIDE_DIMENSION_B: 'Inside Dimension B',
  INSIDE_DIMENSION_H: 'Inside Dimension H',
  PURCHASE_DATE: 'Purchase Date',
  MANUFACTURER: 'Manufacturer',
  BRAND: 'Brand',
  BASIC_PRICE: 'Basic Price',
  TIRE_PRESSURE: 'Tire Pressure',
  TIRE_SIZE_2ND_AXLE: 'Tire Size 2nd Axle',
  TIRE_SIZE_3RD_AXLE: 'Tire Size 3rd Axle',
  TIRE_PRESSURE_2ND_AXLE: 'Tire Pressure 2nd Axle',
  TIRE_PRESSURE_3RD_AXLE: 'Tire Pressure 3rd Axle',
  STATUS: 'Status',
  CHANGE_VEHICLE_STATUS: 'Change Vehicle status',
  ACTIONS: 'Actions',
  NO_DOCUMENT: 'There is no document!',
  GO_TO_VEHICLES: 'Go to Vehicles Page',
  No_VEHICLE: 'There is no such vehicle!',
  DOWNLOAD: 'Download',
  ROWS_PER_PAGE: 'Rows per page:',
  OF: 'of',

  PASSWORD: 'Password',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  COUNTRY: 'Country',

  BASIC_INFO: 'Basic Info',
  ADDITIONAL_INFO: 'Additional Info',
  DOCUMENTS: 'Documents',
  HIRING_INFO: 'Hiring info',

  LICENSE_NUMBER: 'License Number',
  FORKLIFT_LICENSE_NUMBER: 'Forklift License Number',
  FORKLIFT_LICENSE_EXPIRATION: 'Forklift License Expiration',
  FUEL_ID_CARD: 'Fuel Id Card',

  STATUS_CHANGE_CONFIRMATION_MSG: 'Are a sure you want',
  DISABLED_DEACTIVATE_MSG: 'This vehicle is assigned for a trip',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
  DELETE_DEACTIVATE_MSG: 'Vehicle has past trips, do you want to deactivate instead?'
};

export default locale;
