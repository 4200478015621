import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MapContext } from '../Map';
import { selectTripById } from '../store/mapSlice';
import DetailsTab from './tabs/DetailsTab';
import RouteTab from './tabs/RouteTab';

function TripDetails(props) {
  const { t } = useTranslation('mapPage');
  const { setTripSidebarOpen } = useContext(MapContext);
  const routeParams = useParams();
  const tripId = routeParams.id;
  const trip = useSelector((state) => selectTripById(state, tripId));
  const [tabValue, setTabValue] = useState(0);

  if (!trip) {
    return null;
  }

  function handleTabChange(event, value) {
    setTabValue(value);
  }

  return (
    <div className="flex flex-col flex-auto h-full">
      <Box
        className="border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <Toolbar className="flex items-center px-4">
          <IconButton
            onClick={() => setTripSidebarOpen(false)}
            color="inherit"
            size="large"
          >
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>
          <Typography
            className="px-4 font-medium text-16"
            color="inherit"
            variant="subtitle1"
          >
            {t('TRIP_INFO')}
          </Typography>
        </Toolbar>
      </Box>
      {trip && (
        <>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: 'w-full h-20 border-b-1' }}
          >
            <Tab className="h-20" label={t('ROUTE')} />
            <Tab className="h-20" label={t('DETAILS')} />
          </Tabs>
          <div className="w-full px-12 py-6">
            {tabValue === 0 && <RouteTab trip={trip} />}
            {tabValue === 1 && <DetailsTab trip={trip} />}
          </div>
        </>
      )}
    </div>
  );
}

export default TripDetails;
