const locale = {
  PAGE_TITLE: 'MAP',
  ACTIVITY_TYPE: 'Activity',
  TITLE: 'Title',
  LOCATION: 'Location',
  COMMENT: 'Comment',
  CHECKPOINT: 'Checkpoint',
  TRIP_INFO: 'Trip Info',
  ROUTE: 'Route',
  DETAILS: 'Details',
  VEHICLES: 'Vehicles',
  DRIVERS: 'Drivers',
  LOAD_LISTS: ' Load Lists',
  PACKAGES: 'Packages',
  SENDER: 'Sender',
  RECEIVER: 'Receiver',
  ADD: 'Add',
  TRIPS: 'Trips',
  NO_TRIPS: 'There are no trips!',
  EDIT: 'Edit',
  SAVE: 'Save',
  CANCEL: 'Cancel',
};

export default locale;
