import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import withReducer from 'app/store/withReducer';
import { isArray } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import CheckpointSidebar from './sidebars/CheckpointSidebar';
import TripDetails from './sidebars/TripDetails';
import TripList from './sidebars/main/TripList';
import reducer from './store';
import { getTrips } from './store/mapSlice';

const drawerWidth = 300;

export const MapContext = createContext({});

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-content': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    height: '100%',
  },
}));

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    maxWidth: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
}));

function Map(props) {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [mainSidebarOpen, setMainSidebarOpen] = useState(!isMobile);
  const [tripSidebarOpen, setTripSidebarOpen] = useState(false);
  const [checkpointSidebarOpen, setCheckpointSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const routeParams = useParams();
  const tripId = routeParams.id;
  const [page, setPage] = useState(1);
  const pageSize = 5;

  const loadMore = () => {
    setPage(page + 1);
  };

  // Callback function to load more notifications when the "Load More" button intersects the viewport
  const handleIntersection = () => {
    loadMore();
  };

  useEffect(() => {
    let request = {
      searchKeyword: '',
      page: page,
      pageSize: pageSize, // For Now
      sortLabel: 'id',
      sortOrder: 'DESC',   
    };
    dispatch(getTrips(request)).then((val) => {
      const trips = val.payload;
      // By defalt set first item as selected for map view
      if (!tripId && isArray(trips) && trips.length > 0) {
        // navigate(`/map/${trips[0].id}`);
      }
    });
  }, [dispatch, page]);

  useEffect(() => {
    setMainSidebarOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      setMainSidebarOpen(false);
    }
  }, [location, isMobile]);

  // Function to close the drawer
  const closeDrawer = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else {
      setCheckpointSidebarOpen(false);
    }
  };

  return (
    <MapContext.Provider
      value={{
        setMainSidebarOpen,
        setTripSidebarOpen,
        setCheckpointSidebarOpen,
      }}
    >
      <Root
        content={<Outlet />}
        leftSidebarContent={
          <TripList handleIntersection={handleIntersection} />
        }
        leftSidebarOpen={mainSidebarOpen}
        leftSidebarOnClose={() => {
          setMainSidebarOpen(false);
        }}
        leftSidebarWidth={250}
        rightSidebarContent={<TripDetails />}
        rightSidebarOpen={tripSidebarOpen}
        rightSidebarOnClose={() => {
          setTripSidebarOpen(false);
        }}
        rightSidebarWidth={300}
        scroll="content"
      />
      <StyledSwipeableDrawer
        className="h-full absolute z-9999"
        variant="temporary"
        anchor="left"
        open={checkpointSidebarOpen}
        onOpen={(ev) => {}}
        onClose={closeDrawer}
        classes={{
          paper: 'absolute left-0',
        }}
        style={{ position: 'absolute' }}
        ModalProps={{
          keepMounted: false,
          disablePortal: true,
          BackdropProps: {
            classes: {
              root: 'absolute',
            },
          },
        }}
      >
        <CheckpointSidebar />
      </StyledSwipeableDrawer>
    </MapContext.Provider>
  );
}

export default withReducer('map', reducer)(Map);
