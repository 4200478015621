import i18next from 'i18next';
import { lazy } from 'react';

import ar from './i18n/ar';
import de from './i18n/de';
import en from './i18n/en';

i18next.addResourceBundle('en', 'customersPage', en);
i18next.addResourceBundle('de', 'customersPage', de);
i18next.addResourceBundle('ar', 'customersPage', ar);

// Declare a lazy-loaded component Customers, which will be dynamically loaded when needed
const Customers = lazy(() => import('./list/Customers'));
const Customer = lazy(() => import('./add-edit/Customer'));

// CustomersConfig object containing the configuration for the Customers page
const CustomersConfig = {
  // settings object for additional page settings (e.g., layout settings)
  settings: {
    layout: {}, // You can add layout settings here if needed
  },

  // routes array containing the route configurations for the Customers page
  routes: [
    {
      path: '/customers',
      element: <Customers />, // The React element to be rendered for the Customers page
    },
    {
      path: '/customers/:customerId/*',
      element: <Customer />,
    },
  ],
};

export default CustomersConfig;
