import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { lighten } from '@mui/material/styles';
import { useContext } from 'react';
import { MapContext } from './Map';
import MapView from './map/MapView';

const MapSplashScreen = () => {
  const { setMainSidebarOpen } = useContext(MapContext);

  return (
    <>
      <Box
        className="w-full border-b-1 lg:hidden"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <Toolbar className="flex items-center justify-between px-16 w-full">
          <div className="flex items-center">
            <IconButton
              aria-label="Open drawer"
              onClick={() => setMainSidebarOpen(true)}
              className="flex"
              size="large"
            >
              <FuseSvgIcon>heroicons-outline:map</FuseSvgIcon>
            </IconButton>
          </div>
        </Toolbar>
      </Box>
      <MapView />
    </>
  );
};

export default MapSplashScreen;
