import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'src/app/main/utils/Constants';

const DetailsTab = ({ trip }) => {
  const { t } = useTranslation('mapPage');

  if (!trip) {
    return null;
  }

  return (
    <>
      <Typography>{trip?.tripType?.name}</Typography>
      <Typography className="text-md truncate" color="text.secondary">
        <span className="font-medium">{trip?.tripDificulty?.name}</span>
      </Typography>
      <div className="mt-8">
        <Typography className="text-14 font-medium" color="text.secondary">
          {t('VEHICLES')}
        </Typography>
        {trip?.vehicles?.map((vehicle, index) => (
          <div className="flex items-center" key={vehicle?.id}>
            <Typography>{vehicle?.vin}</Typography>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <Typography className="text-14 font-medium" color="text.secondary">
          {t('DRIVERS')}
        </Typography>
        {trip?.drivers?.map((driver, index) => (
          <div className="flex items-center" key={driver?.id}>
            <Typography>
              {driver.userDetails
                ? `${driver.userDetails.first_name} ${driver.userDetails.last_name}`
                : `${driver.username}`}
            </Typography>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <Typography className="text-14 font-medium" color="text.secondary">
          {t('LOAD_LISTS')}
        </Typography>
        {trip?.shipments?.map((loadList, index) => {
          const sender = loadList?.sender;
          const receiver = loadList?.receiver;
          return (
            <div key={loadList?.id} className="flex mb-8">
              <span className="mr-4">&bull;</span>
              <div className="flex flex-col">
                {loadList?.delivery_status && (
                  <Typography className="font-medium" color="text.secondary">
                    {loadList?.delivery_status}
                  </Typography>
                )}

                <div className="flex items-center">
                  <Typography>{loadList.tracking_number}</Typography>
                  {loadList?.packages?.length > 0 && (
                    <Typography className="text-md truncate">
                      <span className="mx-8">-</span>
                      <span className="font-medium">
                        {loadList?.packages?.length} {t('PACKAGES')}
                      </span>
                    </Typography>
                  )}
                </div>
                {(loadList?.delivery_date || loadList?.delivery_location) && (
                  <div className="flex items-center">
                    {loadList?.delivery_date && (
                      <Typography>
                        {loadList?.deliver_date
                          ? format(
                              new Date(loadList?.deliver_date),
                              DATE_FORMAT
                            )
                          : ''}
                      </Typography>
                    )}
                    {loadList.delivery_location && (
                      <Typography className="text-md truncate">
                        {loadList.deliver_date && (
                          <span className="mx-8">-</span>
                        )}
                        <span className="font-medium">
                          {loadList.delivery_location}
                        </span>
                      </Typography>
                    )}
                  </div>
                )}
                <div className="flex items-center">
                  <Typography color="text.secondary">{t('SENDER')}</Typography>
                  <Typography className="text-md truncate">
                    <span className="mx-8" />
                    <span className="font-medium">
                      {sender?.userDetails
                        ? `${sender?.userDetails.first_name} ${sender?.userDetails.last_name}`
                        : `${sender?.username}`}
                    </span>
                  </Typography>
                </div>
                <div className="flex items-center">
                  <Typography color="text.secondary">
                    {t('RECEIVER')}
                  </Typography>
                  <Typography className="text-md truncate">
                    <span className="mx-8" />
                    <span className="font-medium">
                      {receiver?.userDetails
                        ? `${receiver?.userDetails.first_name} ${receiver?.userDetails.last_name}`
                        : `${receiver?.username}`}
                    </span>
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DetailsTab;
