import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// constants
const VAPID_KEY = process.env.REACT_APP_FCM_VAPID;
const API_KEY = process.env.REACT_APP_FCM_API_KEY;
const AUTH_DOMAIN = "logistics-dev-42815.firebaseapp.com";
const PROJECT_ID = "logistics-dev-42815"
const STORAGE_BUCKET = "logistics-dev-42815.appspot.com"
const SENDER_ID = "630830016136"
const APP_ID = process.env.REACT_APP_FCM_APP_ID
const MEASUREMENT_ID = "G-ES2VFFFCZH"


const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID
};


const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

const fetchToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: VAPID_KEY });
    if (currentToken) {
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
      return null;
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
    return null;
  }
};

const requestPermission = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  });
};

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });

export { fetchToken, requestPermission, onMessageListener };