const locale = {
  PAGE_TITLE: 'Load List',
  ID: 'id',
  SEARCH: 'Search',
  ADD: 'Add',
  EDIT: 'Edit',
  DELETE: 'Delete',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  LOADING: 'Loading',
  SALUTATION: 'Salutation',
  DELETE_LOAD_LIST: 'Delete LoadList',
  CONFIRMATION_MESSAGE: 'Are you sure you want to delete',
  EMAIL: 'Email Address',

  PHONE_NUMBER: 'Phone Number',
  MOBILE_NUMBER: 'Mobile Number',
  MOBILE_BUSINESS_NUMBER: 'Mobile Buisness Number',
  STREET: 'Street',
  ADDRESS: 'Address',
  CITY: 'City',
  STATE: 'State',
  DISTRICT: 'District',
  LOCATION: 'Location',
  POSTAL_CODE: 'Postal Code',
  STATUS: 'Status',
  CHANGE_STATUS: 'Change status',
  ACTIONS: 'Actions',
  ROWS_PER_PAGE: 'Rows per page:',
  OF: 'of',

  PASSWORD: 'Password',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  COUNTRY: 'Country',
  TRACKING_NUMBER: 'Load List Number',
  BASIC_INFO: 'Basic Info',
  ADDITIONAL_INFO: 'Additional Info',
  PACKAGES: 'Packages',
  ADD_PACKAGE: 'Add a package',
  GO_TO_LOADLIST: 'Go to LoadLists Page',
  NO_LOADLIST: 'There is no such loadList!',
  SUPPLIER: 'Supplier',
  CUSTOMER: 'Customer',
  NO_OF_ITEMS: 'No of items',
  WIDTH: 'Width',
  HEIGHT: 'Height',
  LENGTH: 'Length',
  VOLUME: 'Volume',
  WEIGHT: 'Weight',
  PRIORITY: 'Priority',
  CONTENT: 'Content',
  NOTES: 'Notes',

  STATUS_CHANGE_CONFIRMATION_MSG: 'Are a sure you want',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
  NOTASSIGNED: 'Not Assigned',
  ASSIGNED: 'Assigned',
  INPROGRESS: 'In Progress',
  DELIVERED: 'Delivered',
  NOTDELIVERED: 'Not Delivered',
  RETURNED: 'Returned',
  DELIVERY_LOCATION: 'Delivery Location',
  DELIVERY_NOTES: 'Delivery Notes',
  DELIVERY_DATE: 'Delivery Date',
  DELIVERY_STATUS: 'Delivery Status',
  COST: 'Cost',
  CUSTOMER_REQUIREMENTS: 'Customer Requirements',
  ADDRESS_HELPER_TEXT: 'Please select customer',
  DISABLED_LOAD_MSG: 'This load is assigned for a trip that is in progress',
};

export default locale;
