const locale = {
  PAGE_TITLE: 'Starter Kit',
  ADD: 'Add',
  EDIT: 'Edit',
  UPDATE: 'Update',
  DELETE: 'Delete',
  DELETE_STARTER_KIT: 'Delete Starter Kit',
  EDIT_CATEGORY: 'Edit Category',
  ADD_STARTER_KIT: 'Add',
  EDIT_STARTER_KIT: 'Edit starter Kit',
  CANCEL: 'Cancel',
  CATEGORIES: 'Categories',
  CATEGORY: 'Category',
  QUESTION: 'Question',
  SEARCH_STARTER_KIT: 'Search Starter Kit',
  STATUS: 'Status',
  ROWS_PER_PAGE: 'Rows per page',
  OF: 'Of',
  CREATE_NEW_CATEGORY: 'Create new category',
  ADD_EDIT_CATEGORIES: 'Add Categories',
  ADD_NEW_QUESTION: 'Add new question',
  NOT_ELIGIBLE_TO_DELETE: 'Trips associated with the starter kit',
  CONFIRMATION_MESSAGE: 'Are you sure you want to delete ',
  STARTER_KIT_DEACTIVATION_MSG: 'Starter Kits are associated with this category.'
};

export default locale;
