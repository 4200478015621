import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import {
  selectFuseCurrentLayoutConfig,
  selectToolbarTheme,
} from 'app/store/fuse/settingsSlice';
import ThemeMode from 'app/theme-layouts/shared-components/ThemeMode';
import { getNotificationsCount } from 'app/theme-layouts/shared-components/notificationPanel/store/dataSlice';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import NavbarToggleButton from '../../shared-components/NavbarToggleButton';
import UserMenu from '../../shared-components/UserMenu';
import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton';
import { selectUser } from 'app/store/userSlice';

function ToolbarLayout1(props) {
  const user = useSelector(selectUser);
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const dispatch = useDispatch();
  const [unreadCount, setUnreadCount] = useState(null);
  const userId = user?.id;

  useEffect(() => {
    // get unread notifications
    dispatch(
      getNotificationsCount({ user_id: userId, field: 'is_read', value: 0 })
    ).then((val) => {
      setUnreadCount(val.payload);
    });
    return () => {};
  }, [dispatch]);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? toolbarTheme.palette.background.paper
              : toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
            <ThemeMode />

            <LanguageSwitcher />

            {/* <AdjustFontSize /> */}

            {/* <FullScreenToggle /> */}

            {/* <NavigationSearch /> */}

            {/* <Hidden lgUp>
              <ChatPanelToggleButton />
            </Hidden> */}

            {/* <QuickPanelToggleButton /> */}

            <NotificationPanelToggleButton unreadCount={unreadCount} />

            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden lgDown>
                {!navbar.open && (
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                )}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
