const locale = {
  APPLICATIONS: 'تطبيقات',
  DaSHBOARD: 'مثال',
  USERS: 'Users',
  ROLES: 'Roles',
  DRIVERS: 'Drivers',
  CLIENTS: 'Business Partners',
  CUSTOMERS: 'Customers',
  SUPPLIERS: 'Suppliers',
  TRIP_PLAN: 'Trip Plan',
  TRIPS: 'Trips',
  LOADLIST: 'Load List',
  VEHICLES: 'Vehicles',
  SCHEDULED_TRIPS: 'Scheduled Trips',
  STARTER_KIT: 'Starter Kit',
  MAP: 'Map',
  OVERVIEW: 'Overview',
};

export default locale;
