// Define your API key
const TOMTOM_API_KEY = process.env.REACT_APP_MAP_API_KEY;

export const searchLocations = async (inputValue) => {
  try {
    const response = await fetch(
      `https://api.tomtom.com/search/2/search/${inputValue}.json?key=${TOMTOM_API_KEY}&limit=5`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw error; // Propagate the error to the caller
  }
};

// Function to calculate a route
export const calculateRoute = async (startLocation, endLocation) => {
  // Check if both startLocation and endLocation have positions
  if (!startLocation?.position || !endLocation?.position) {
    console.warn(
      'startLocation and endLocation must have positions for route calculation.'
    );
    return Promise.reject('Missing positions for route calculation');
  }

  const { lat: startLat, lon: startLon } = startLocation.position;
  const { lat: endLat, lon: endLon } = endLocation.position;

  try {
    const response = await fetch(
      `https://api.tomtom.com/routing/1/calculateRoute/${startLat},${startLon}:${endLat},${endLon}/json?key=${TOMTOM_API_KEY}`
    );
    const data = await response.json();
    const lengthInMeters = data.routes[0]?.summary?.lengthInMeters;
    // Calculate length_in_km with 2 decimal places
    const lengthInKilometers = (lengthInMeters / 1000).toFixed(2);
    return lengthInKilometers;
  } catch (error) {
    console.error('Error calculating route:', error);
    throw error; // Propagate the error to the caller
  }
};

export const fetchRoute = async (waypoints) => {
  try {
    const response = await fetch(
      `https://api.tomtom.com/routing/waypointoptimization/1/best?key=${TOMTOM_API_KEY}`, // Create the URL for the waypoint optimization API_KEY
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          waypoints: waypoints.map(function (element) {
            return {
              point: {
                latitude: element.lat,
                longitude: element.lng,
              },
            };
          }),
        }),
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching route:', error);
    throw error;
  }
};

export const getGeoCode = async (request) => {
  const countryCode = request?.countryCode;
  const postalCode = request?.postalCode;
  const municipality = request?.city;
  const countrySubdivision = request?.state;

  let query = '';
  if (countryCode) query += `&countryCode=${countryCode}`;
  if (postalCode) query += `&postalCode=${postalCode}`;
  if (municipality) query += `&municipality=${municipality}`;
  if (countrySubdivision) query += `&countrySubdivision=${countrySubdivision}`;

  return fetch(
    `https://api.tomtom.com/search/2/structuredGeocode.json?key=${TOMTOM_API_KEY}${query}`
  )
    .then((response) => response.json())
    .catch((error) => {
      console.error('Error fetching locations:', error);
      throw error; // Propagate the error to the caller
    });
};

export const fetchLatLong = async (address) => {
  try {
    const response = await fetch(
      `https://api.tomtom.com/search/2/geocode/${encodeURIComponent(
        address
      )}.json?key=${TOMTOM_API_KEY}`
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const lat = data.results[0].position.lat;
      const lng = data.results[0].position.lon;
      return { lat, lng };
    } else {
      throw new Error('No results found');
    }
  } catch (error) {
    console.error('Error fetching data: ', error);
    throw error;
  }
};

export const reverseGeocode = async (latitude, longitude) => {
  const url = `https://api.tomtom.com/search/2/reverseGeocode/crossStreet/${latitude},${longitude}.json?key=${TOMTOM_API_KEY}`;

  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      if (data.addresses && data.addresses.length > 0) {
        return data.addresses[0].address.freeformAddress;
      } else {
        return `${latitude},${longitude}`;
      }
    } else {
      return `${latitude},${longitude}`;
    }
  } catch (error) {
    console.error('Error:', error);
    return `${latitude},${longitude}`;
  }
};
