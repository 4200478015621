import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getVehicleLocationHistory,
  selectTrip,
  selectTripCheckpoints,
} from '../store/mapSlice';
// TomTom SDK
import * as tt from '@tomtom-international/web-sdk-maps';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import * as tts from '@tomtom-international/web-sdk-services';
import { useDispatch } from 'react-redux';
import { vanSvg } from 'src/app/main/utils/svgConstants';
import { fetchRoute, reverseGeocode } from 'src/app/services/TomTomApiService';
import { hidePleaseWait } from 'src/app/services/commonService';

const MapView = () => {
  const dispatch = useDispatch();
  const trip = useSelector(selectTrip);
  const checkpoints = useSelector(selectTripCheckpoints);
  const [markers, setMarkers] = useState([]); // Store markers
  const [routeLayers, setRouteLayers] = useState([]); // Store route layers
  // Add a state variable to track map style loading
  const [mapStyleLoaded, setMapStyleLoaded] = useState(false);
  // const AMSTERDAM = { lon: 10.553895, lat: 51.494032 };
  const GERMANY = { lon: '10.953895', lat: '51.494032' };

  const map = useRef();
  const mapContainer = useRef();
  // Create bounds from the coordinates
  const bounds = new tt.LngLatBounds();

  // Function to remove markers and route layers
  const removeMarkersAndRoutes = () => {
    markers.forEach((marker) => marker.remove()); // Remove markers
    setMarkers([]); // Clear markers array

    routeLayers.forEach((layerId) => {
      if (map.current.getLayer(layerId)) {
        map.current.removeLayer(layerId); // Remove route layers if they exist
      }
    });

    routeLayers.forEach((sourceId) => {
      if (map.current.getSource(sourceId)) {
        map.current.removeSource(sourceId); // Remove route sources if they exist
      }
    });

    setRouteLayers([]); // Clear routeLayers array
  };

  const getWaypoints = (trip) => {
    if (!trip || !checkpoints) return [];
    else {
      const allCoordinates = checkpoints?.map((checkpoint) => {
        return {
          desc: checkpoint?.notes,
          location: {
            lat: parseFloat(checkpoint?.lat),
            lng: parseFloat(checkpoint?.long),
          },
        };
      });
      return allCoordinates;
    }
  };

  const createMarker = (icon, position, color, popupText) => {
    const popup = new tt.Popup({
      offset: 50,
      className: 'text-black text-bold',
    }).setText(popupText);
    // Create the marker with or without the 'element' based on the 'icon' presence
    let marker;
    if (icon) {
      marker = new tt.Marker({ element: icon })
        .setLngLat(position)
        .setPopup(popup)
        .addTo(map.current);
    } else {
      marker = new tt.Marker()
        .setLngLat(position)
        .setPopup(popup)
        .addTo(map.current);
    }
    setMarkers((prevMarkers) => [...prevMarkers, marker]); // Add the new marker to the array
    return marker;
  };

  useEffect(() => {
    map.current = tt.map({
      key: process.env.REACT_APP_MAP_API_KEY,
      container: mapContainer.current.id,
      center: GERMANY,
      zoom: 8,
      language: 'en-GB',
    });
    map.current.addControl(new tt.FullscreenControl());
    map.current.addControl(new tt.NavigationControl());
    // Listen for the map's 'load' event to detect when the style is loaded
    map.current.on('load', () => {
      setMapStyleLoaded(true);
    });

    return () => {
      map.current.remove();
    };
  }, []);

  const createRoute = function (options, pathColor = 'blue') {
    tts.services.calculateRoute(options).then(function (response) {
      var features = response.toGeoJson().features;
      features.forEach(function (feature, index) {
        const layerId = 'route' + index;
        map.current.addLayer({
          id: layerId,
          type: 'line',
          source: {
            type: 'geojson',
            data: feature,
          },
          paint: {
            'line-color': pathColor,
            'line-opacity': 0.7,
            'line-width': 10,
            'line-dasharray': [1, 0, 1, 0],
          },
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
        });
        setRouteLayers((prevLayers) => [...prevLayers, layerId]);
      });
      features[0].geometry.coordinates.forEach(function (point) {
        bounds.extend(point);
      });
      map.current.fitBounds(bounds, {
        duration: 300,
        padding: 50,
        maxZoom: 14,
      });
    });
  };

  // Show vehicles travelled route
  const showVehicleHistoryRoute = async () => {
    trip?.vehicles?.map(async (vehicle) => {
      const tripId = trip?.id;
      const vehicleId = vehicle?.id;
      dispatch(getVehicleLocationHistory({ tripId, vehicleId })).then(
        async (result) => {
          if (getVehicleLocationHistory.fulfilled.match(result)) {
            const locationHistoryData = result.payload;
            if (locationHistoryData.length > 0) {
              const locations = locationHistoryData?.map((location) => ({
                lat: location?.lat,
                lng: location?.long,
              }));

              // Get the last location in the history
              const lastLocation =
                locationHistoryData[locationHistoryData.length - 1];

              createRoute(
                {
                  key: process.env.REACT_APP_MAP_API_KEY,
                  locations: locations,
                },
                'green'
              );
              // Set movingVehicle's lat and lng
              const movingVehicle = {
                lat: lastLocation.lat,
                lng: lastLocation.long,
              };

              const currentPlace = await reverseGeocode(
                movingVehicle.lat,
                movingVehicle.lng
              );

              // Create a label for the marker with the index + 1 using Tailwind CSS classes
              const vehicleMarker = document.createElement('div');
              vehicleMarker.className = 'w-40 h-40';
              vehicleMarker.innerHTML = vanSvg;

              createMarker(
                vehicleMarker,
                {
                  lat: movingVehicle.lat,
                  lng: movingVehicle.lng,
                },
                'orange',
                `${vehicle?.vin} - ${currentPlace}`
              );
            }
          }
        }
      );
    });
  };

  // Define createMarkersForWaypoints function
  const createMarkersForWaypoints = (waypoints) => {
    waypoints?.forEach(function (waypoint, index) {
      // if (index !== 0) {
      const popup = new tt.Popup({
        offset: 50,
        className: 'text-black font-bold',
      }).setText(`${waypoint?.desc}`);
      const marker = new tt.Marker()
        .setPopup(popup)
        .setLngLat(waypoint?.location)
        .addTo(map.current);
      setMarkers((prevMarkers) => [...prevMarkers, marker]); // Add the new marker to the array
      // }
    });
  };

  // create route
  const createWaypoints = async () => {
    const waypoints = getWaypoints(trip);
    if (waypoints?.length < 2) {
      return;
    }

    showVehicleHistoryRoute();

    createMarkersForWaypoints(waypoints);
    // Define the travel options separately
    const travelOptions = {
      travelMode: 'truck', // [ car, truck ]
      // vehicleMaxSpeed: max_speed_kph,
      // vehicleWeight: max_weight_kg,
      // vehicleAxleWeight: max_axle_weight_kg,
      // vehicleLength: vehicle_length_m,
      // vehicleWidth: vehicle_width_m,
      // vehicleHeight: vehicle_height_m,
      // vehicleLoadType: [[ USHazmatClass1, USHazmatClass2, USHazmatClass3, USHazmatClass4, USHazmatClass5, USHazmatClass6, USHazmatClass7, USHazmatClass8, USHazmatClass9, otherHazmatExplosive, otherHazmatGeneral, otherHazmatHarmfulToWater ]],
      // vehicleAdrTunnelRestrictionCode: 'tunnel_restriction_code',
      // outputExtensions: ['travelTimes', 'routeLengths'],
      // traffic: 'historical' | 'live',
      // departAt: dateTime | 'any' | 'now',
      vehicleCommercial: true,
      vehicleHeading: 180,
    };
    // Filter out waypoints with undefined coordinates
    const validWaypoints = waypoints?.filter((waypoint) => waypoint?.location);

    if (validWaypoints?.length === 0) {
      return;
    }

    const coordinates = validWaypoints?.map((waypoint) => waypoint?.location);
    // Add a try-catch block around the fetchRoute call
    try {
      const data = await fetchRoute(coordinates);

      let solution = data?.optimizedOrder;
      let locations = solution?.map(function (order, index) {
        return coordinates[order];
      });

      createRoute(
        {
          key: process.env.REACT_APP_MAP_API_KEY,
          locations: locations,
          ...travelOptions, // Spread the travel options
        },
        'blue'
      );
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      coordinates?.forEach((coordinate) => {
        if (coordinate.lat && coordinate.lng) {
          const lngLat = new tt.LngLat(coordinate.lng, coordinate.lat);
          bounds.extend(lngLat);
        } else {
          return;
        }
      });
      // Check if the map style has loaded before adjusting the map's view
      if (mapStyleLoaded) {
        map.current.fitBounds(bounds, {
          duration: 300,
          padding: 50,
          maxZoom: 14,
        });
      }
    } finally {
      setTimeout(() => {
        hidePleaseWait();
      }, 1000); // Add a delay before hiding the loading indicator
    }
  };

  useEffect(() => {
    if (trip) {
      if (trip) {
        removeMarkersAndRoutes(); // Remove existing markers and routes

        // Check if the map style has loaded before creating the route
        if (mapStyleLoaded) {
          createWaypoints(); // Create new markers and route
        }
      }
    }
  }, [trip, mapStyleLoaded, checkpoints]);

  return (
    <div className="flex flex-auto h-full min-h-0 w-full">
      <div className={clsx('flex flex-1 z-10 relative')}>
        <div className="flex flex-col flex-1 items-center justify-center w-full">
          {/* Map with route and markers */}
          <div ref={mapContainer} className="h-full w-full" id="map" />
        </div>
      </div>
    </div>
  );
};

export default MapView;
