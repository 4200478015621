export const DEFAULT_SALUTATION = 'Mr';
export const DEFAULT_ROLE = 'admin';
export const DRIVER_ROLE = 'driver';
export const DEFAULT_COUNTRY = 'de' || 'in';
export const DEFAULT_VEHICLE_TYPE = 'trailer';
export const DEFAULT_TRIP_STATUS = 'NOTASSIGNED';
// since these roles have separate listing we exclude these roles in user listing
export const EXCLUDED_ROLES = ['driver', 'client', 'supplier', 'customer'];
export const NON_EDITABLE_ROLES = [
  'admin',
  'driver',
  'client',
  'supplier',
  'customer',
];
export const NON_EDITABLE_TRIP_STATUSES = ['COMPLETED'];

export const CUSTOMER_ROLE = 'customer';
export const SUPPLIER_ROLE = 'supplier';

export const salutations = ['Mr', 'Ms', 'Mrs'];
export const FUEL_TYPES = ['Diesel', 'Petrol', 'Gas', 'Electric'];
export const ACTIVITY_TYPES = [
  // 'On Load',
  // 'Off Load',
  // 'Parking',
  // 'Refueling',
  // 'Other',
  'Break',
];
export const ACTIVITY_DURATIONS = [15, 30, 45];
export const sortingOptions = [
  {
    id: 'username',
    label: 'User Name',
    key: 'username',
    value: 'username',
    direction: 'desc', // used for api call to pass for sort_order
  },
];

export const DATE_FORMAT = 'dd.MM.yyyy';
export const TIME_FORMAT = 'hh:mm aa';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy hh:mm aa';

// Define colors and titles for each tab
export const calendarTabInfo = [
  {
    id: 1,
    tab: 'vehicles',
    eventTypes: [{ title: 'trips', color: '#419388' }],
  },
  {
    id: 2,
    tab: 'drivers',
    eventTypes: [{ title: 'trips', color: '#4151b0' }],
  },
  {
    id: 3,
    tab: 'trips',
    eventTypes: [{ title: 'trips', color: '#d63e63' }],
  },
];

import mockApi from '../../../@mock-api/mock-api.json';

let tasksDB = mockApi.components.examples.tasks.value;

export const sampleTasks = tasksDB;
