// Function to get formatted vehicle information
export const getFormattedVehicleInfo = (trip) => {
  const vehicles = trip?.vehicles || [];
  if (vehicles.length === 0) {
    return null;
  } else if (vehicles.length === 1) {
    return vehicles[0]?.vin;
  } else {
    const firstTwoVehicles = vehicles.slice(0, 2);
    const remainingCount = vehicles.length - 2;
    const formattedVehicles = firstTwoVehicles.map((vehicle) => (
      <span key={vehicle.id}>{vehicle.vin}</span>
    ));
    if (remainingCount > 0) {
      return (
        <>
          {formattedVehicles[0]}
          <br />
          {formattedVehicles[1]} +{remainingCount}
        </>
      );
    } else {
      return (
        <>
          {formattedVehicles[0]}
          <br />
          {formattedVehicles[1]}
        </>
      );
    }
  }
};

// Function to get formatted driver names
export const getFormattedDriverNames = (trip) => {
  const drivers = trip?.drivers || [];
  if (drivers.length === 0) {
    return null;
  } else if (drivers.length === 1) {
    const driver = drivers[0];
    return driver.userDetails
      ? `${driver.userDetails.first_name} ${driver.userDetails.last_name}`
      : driver.username;
  } else {
    const firstTwoDrivers = drivers.slice(0, 2);
    const remainingCount = drivers.length - 2;
    const formattedDrivers = firstTwoDrivers.map((driver) => (
      <span key={driver.id}>
        {driver?.userDetails
          ? `${driver?.userDetails?.first_name} ${driver?.userDetails?.last_name}`
          : driver?.username}
      </span>
    ));
    if (remainingCount > 0) {
      return (
        <>
          {formattedDrivers[0]}
          <br />
          {formattedDrivers[1]} +{remainingCount}
        </>
      );
    } else {
      return (
        <>
          {formattedDrivers[0]}
          <br />
          {formattedDrivers[1]}
        </>
      );
    }
  }
};

export const getStartEndLocationText = (trip) => {
  // Initialize variables for start and end location text
  let startLocationText = '';
  let endLocationText = '';

  // Check if start_location exists and is not null
  if (trip.start_location) {
    startLocationText = trip.start_location;
  }

  // Check if end_location exists and is not null
  if (trip.end_location) {
    endLocationText = trip.end_location;
  }

  // Combine start and end location text with appropriate separators
  if (startLocationText && endLocationText) {
    return `${startLocationText} - ${endLocationText}`;
  } else if (startLocationText) {
    return startLocationText;
  } else if (endLocationText) {
    return endLocationText;
  }

  // Return a empty message if both locations are empty
  return '';
};

export const getLocationDisplayValue = (location) => {
  if (!location) {
    return '';
  } else if (location.type === 'POI' && location.poi && location.poi.name) {
    return location.poi.name;
  } else {
    const address = location?.address || {}; // Ensure address is defined
    const {
      streetName,
      municipalitySubdivision,
      municipality,
      countrySubdivision,
      postalCode,
      country,
    } = address;

    // Create an array to store non-empty address components
    const addressComponents = [];

    if (streetName) {
      addressComponents.push(streetName);
    }
    if (municipalitySubdivision) {
      addressComponents.push(municipalitySubdivision);
    }
    if (municipality) {
      addressComponents.push(municipality);
    }
    if (countrySubdivision) {
      addressComponents.push(countrySubdivision);
    }
    if (postalCode) {
      addressComponents.push(postalCode);
    }
    if (country) {
      addressComponents.push(country);
    }

    // Join non-empty address components with commas
    const formattedAddress = addressComponents.join(', ');

    if (formattedAddress) {
      return formattedAddress;
    } else {
      return '';
    }
  }
};

export function showPleaseWait() {
  var pleaseWaitDialog = document.querySelector('#pleaseWaitDialog');

  if (pleaseWaitDialog === null) {
    pleaseWaitDialog = document.createElement('div');
    pleaseWaitDialog.id = 'pleaseWaitDialog';
    pleaseWaitDialog.className = 'preloader';
    pleaseWaitDialog.setAttribute('data-backdrop', 'static');
    pleaseWaitDialog.setAttribute('data-keyboard', 'false');
    pleaseWaitDialog.setAttribute('role', 'dialog');

    // Create and append an overlay element
    var overlay = document.createElement('div');
    overlay.className = 'opacity-20 fixed inset-0 z-30 bg-black';
    pleaseWaitDialog.appendChild(overlay);

    document.body.appendChild(pleaseWaitDialog);
  }

  pleaseWaitDialog.style.display = 'inline';
}

export function hidePleaseWait() {
  document.querySelector('#pleaseWaitDialog').style.display = 'none';
}
