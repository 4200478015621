export const vanSvg = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 820.000000 583.000000"
preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,583.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1936 5393 c-32 -8 -94 -53 -120 -87 -24 -31 -43 -86 -48 -136 l-3
-25 -285 -5 c-253 -4 -288 -7 -312 -23 -27 -19 -58 -81 -58 -118 0 -26 23 -69
49 -93 21 -19 48 -20 935 -20 912 -1 915 -1 943 -22 61 -46 63 -142 3 -199
l-31 -30 -1142 -5 c-885 -4 -1147 -8 -1164 -17 -83 -48 -95 -178 -19 -224 30
-18 62 -19 1004 -19 1054 0 1011 2 1041 -54 30 -55 8 -143 -43 -177 -18 -12
-160 -15 -872 -19 l-851 -5 -36 -28 c-71 -54 -75 -159 -8 -204 l34 -23 1079 0
1080 0 34 -34 c29 -29 34 -41 34 -81 0 -52 -18 -90 -58 -120 -27 -20 -47 -20
-1327 -25 -1230 -5 -1301 -6 -1326 -23 -25 -17 -39 -41 -54 -95 -9 -31 11 -84
41 -113 l26 -24 936 -3 937 -2 29 -23 c40 -32 48 -110 17 -160 -40 -66 -48
-67 -444 -67 l-356 0 -5 -52 c-6 -55 -18 -204 -36 -448 -6 -80 -17 -217 -25
-306 -17 -204 -7 -253 68 -313 26 -22 44 -26 156 -33 69 -5 176 -6 236 -4 61
3 135 5 166 5 l56 1 12 76 c40 245 192 473 414 617 90 60 281 116 392 117 57
0 181 -22 241 -43 148 -53 280 -167 367 -317 56 -98 97 -258 97 -381 l0 -69
1213 0 1213 0 17 87 c33 171 109 319 234 457 61 66 181 155 261 191 287 131
616 73 816 -145 60 -65 133 -194 162 -284 18 -58 27 -113 31 -193 l6 -113 82
0 c73 0 87 3 123 28 50 33 89 83 102 132 6 19 19 145 30 280 10 135 24 301 29
370 6 69 16 183 21 253 6 70 11 181 11 245 1 183 -40 287 -168 428 -15 17 -59
68 -98 114 -38 46 -101 120 -140 163 -38 44 -81 94 -95 111 -62 78 -276 324
-304 350 -17 15 -69 47 -116 70 l-85 41 -454 3 -454 3 7 92 c3 51 16 205 28
343 19 215 20 258 9 295 -16 54 -84 123 -131 132 -37 8 -4125 7 -4154 0z
m5032 -1083 c119 -11 159 -39 318 -226 71 -83 181 -211 244 -285 63 -74 135
-158 158 -186 l44 -53 -716 0 c-394 0 -716 3 -716 8 0 4 4 45 10 92 5 47 14
150 20 230 17 238 31 404 36 418 3 10 59 12 261 11 142 -1 295 -5 341 -9z"/>
<path d="M2925 2573 c-51 -7 -128 -34 -185 -66 -153 -85 -263 -214 -330 -387
-28 -73 -33 -101 -37 -200 -7 -147 6 -213 62 -326 97 -194 282 -309 495 -307
288 4 552 221 632 522 25 96 28 249 5 344 -48 198 -206 363 -394 412 -53 14
-175 18 -248 8z m190 -343 c23 -10 62 -41 87 -69 113 -125 96 -327 -38 -451
-72 -67 -129 -92 -213 -92 -113 0 -198 57 -246 164 -55 121 -29 257 68 364 94
103 222 135 342 84z"/>
<path d="M6865 2574 c-259 -66 -463 -269 -526 -524 -22 -88 -18 -271 8 -359
92 -308 409 -477 708 -376 105 35 175 76 248 144 101 95 155 181 210 336 27
78 31 292 5 370 -46 142 -125 255 -230 326 -92 63 -154 81 -288 85 -63 1 -124
1 -135 -2z m213 -351 c98 -46 159 -165 149 -290 -9 -118 -82 -227 -192 -286
-32 -18 -57 -22 -131 -22 -90 0 -92 1 -148 39 -68 47 -103 101 -118 186 -34
182 88 365 266 400 57 11 112 2 174 -27z"/>
</g>
</svg>
`;
