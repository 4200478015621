import CustomersConfig from './clients/customers/customersConfig';
import SuppliersConfig from './clients/suppliers/suppliersConfig';
import DriversConfig from './drivers/driversConfig';
import LoadListsConfig from './load-lists/loadListsConfig';
import MapConfig from './map/MapConfig';
import RolesConfig from './roles/rolesConfig';
import StarterKitConfig from './starter-kit/starterkitConfig';
import TripHubConfig from './triphub/tripsConfig';
import TripsConfig from './trips/tripsConfig';
import UsersConfig from './users/usersConfig';
import VehiclesConfig from './vehicles/vehiclesConfig';

// Create an array containing the page configurations
const pagesConfigs = [
  UsersConfig,
  RolesConfig,
  DriversConfig,
  SuppliersConfig,
  CustomersConfig,
  LoadListsConfig,
  VehiclesConfig,
  TripsConfig,
  StarterKitConfig,
  MapConfig,
  TripHubConfig,
];

export default pagesConfigs;
