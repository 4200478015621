import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import { useContext, useState } from 'react';
import { MapContext } from '../Map';

const TripMoreMenu = (props) => {
  const { setTripSidebarOpen } = useContext(MapContext);

  const { className } = props;

  const [moreMenuEl, setMoreMenuEl] = useState(null);

  return (
    <div className={className}>
      <IconButton
        aria-owns={moreMenuEl ? 'main-more-menu' : null}
        aria-haspopup="true"
        onClick={() => {
          setTripSidebarOpen(true);
        }}
        size="large"
      >
        <FuseSvgIcon>heroicons-outline:information-circle</FuseSvgIcon>
      </IconButton>
    </div>
  );
};

export default TripMoreMenu;
