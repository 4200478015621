import i18next from 'i18next';
import { lazy } from 'react';

import ar from './i18n/ar';
import de from './i18n/de';
import en from './i18n/en';

i18next.addResourceBundle('en', 'loadListsPage', en);
i18next.addResourceBundle('de', 'loadListsPage', de);
i18next.addResourceBundle('ar', 'loadListsPage', ar);

// Declare a lazy-loaded component LoadLists, which will be dynamically loaded when needed
const LoadLists = lazy(() => import('./list/LoadLists'));
const LoadList = lazy(() => import('./add-edit/LoadList'));

// LoadListsConfig object containing the configuration for the LoadLists page
const LoadListsConfig = {
  // settings object for additional page settings (e.g., layout settings)
  settings: {
    layout: {}, // You can add layout settings here if needed
  },

  // routes array containing the route configurations for the LoadLists page
  routes: [
    {
      path: '/load-list',
      element: <LoadLists />, // The React element to be rendered for the LoadLists page
    },
    {
      path: 'load-list/:loadListId/*',
      element: <LoadList />,
    },
  ],
};

export default LoadListsConfig;
