import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "@lodash";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { showMessage } from "app/store/fuse/messageSlice";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import jwtService from "../../auth/services/jwtService";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  username: yup.string().required("You must enter a username"),
  password: yup.string().required("Please enter your password."),
});

const defaultValues = {
  username: "",
  password: "",
  remember: true,
};

function SignInPage() {
  const dispatch = useDispatch(); // To dispatch actions to the Redux store

  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  async function onSubmit({ username, password }) {
    setShowLoader(true);
    setErrorMsg("");
    // Trim values before api call
    await jwtService
      .signInWithUsernameAndPassword(
        String(username).trim(),
        String(password).trim()
      )
      .then((user) => {
        // No need to do anything, user data will be set at app/auth/AuthContext
        const roles = user.roles || [];
        if (roles.some((role) => role.slug === "admin")) {
          return;
        } else {
          dispatch(
            showMessage({
              variant: "error",
              message: `You don't have access to login here, please contact administrator.`,
            })
          );
        }
      })
      .catch((_errors) => {
        console.log("err ", _errors);
        // If api server is unavailable
        if (_errors?.status === 500 || _errors === undefined) {
          setErrorMsg("Please try again later");
          setShowLoader(false);
        } else {
          setErrorMsg(_errors.message);
          setShowLoader(false);
        }
      });
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="w-48" src="assets/images/logo/logo.png" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Sign in
          </Typography>
          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Username"
                  autoFocus
                  type="username"
                  error={!!errors.username}
                  helperText={errors?.username?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type={showPassword ? "text" : "password"} // Toggle between text and password type
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <FuseSvgIcon
                        className="text-gray-500 cursor-pointer"
                        onClick={handleTogglePassword}
                        edge="end"
                        aria-label="toggle password visibility"
                      >
                        {showPassword
                          ? `heroicons-outline:eye`
                          : `heroicons-outline:eye-off`}
                      </FuseSvgIcon>
                    ),
                  }}
                />
              )}
            />
            {errorMsg?.length !== 0 && (
              // <div className="text-red-700"><Icon className="pt-3">error</Icon> <span className="pb-4">{errorMsg}</span></div>
              <div className="flex items-center justify-center opacity-75 text-red-700">
                <FuseSvgIcon className="text-20 mx-8" color="inherit">
                  heroicons-outline:information-circle
                </FuseSvgIcon>
                <div className="text-14 font-medium whitespace-nowrap">
                  {errorMsg}
                </div>
              </div>
            )}
            {showLoader && (
              <div className="flex justify-center text-center">
                <FuseSvgIcon className="animate-spin text-gray-500">
                  heroicons-outline:refresh
                </FuseSvgIcon>
              </div>
            )}
            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid || showLoader}
              type="submit"
              size="large"
            >
              Sign in
            </Button>
          </form>
        </div>
      </Paper>
      <Box
        className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
        sx={{ backgroundColor: "primary.main" }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100">
            <div>Welcome to</div>
            <div>Logistics</div>
          </div>
          <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
            Logistics management encompasses driver and client coordination,
            financial oversight, damage control, strategic trip planning, and
            efficient management of vehicles and package logistics.
          </div>
        </div>
      </Box>
    </div>
  );
}

export default SignInPage;
