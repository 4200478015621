const CACHE_KEY_COUNTRY = 'defaultCountry';
const CACHE_KEY_VEHICLE_TYPE = 'defaultVehicleType';
const CACHE_KEY_DEFAULT_ROLE = 'defaultRole';
const CACHE_KEY_DRIVER_ROLE = 'driverRole';
const CACHE_KEY_CUSTOMER_ROLE = 'customerRole';
const CACHE_KEY_SUPPLIER_ROLE = 'supplierRole';
const CACHE_KEY_CATEGORY = 'starterKitCategory';

const CacheService = {
  // Save the default country in the Local Storage
  saveDefaultCountry: (country) => {
    try {
      localStorage.setItem(CACHE_KEY_COUNTRY, JSON.stringify(country));
    } catch (error) {
      // Handle errors, e.g., when Local Storage is disabled or full.
      console.error('Error saving default country to Local Storage:', error);
    }
  },

  // Retrieve the default country from the Local Storage
  getDefaultCountry: () => {
    const cachedCountry = localStorage.getItem(CACHE_KEY_COUNTRY);
    if (cachedCountry) {
      return cachedCountry ? JSON.parse(cachedCountry) : null;
    } else {
      return null;
    }
  },

  // Save the default vehicle type in the Local Storage
  saveVehicleType: (type) => {
    try {
      localStorage.setItem(CACHE_KEY_VEHICLE_TYPE, JSON.stringify(type));
    } catch (error) {
      // Handle errors, e.g., when Local Storage is disabled or full.
      console.error(
        'Error saving default vehicle type to Local Storage:',
        error
      );
    }
  },

  // Retrieve the default vehicle type from the Local Storage
  getVehicleType: () => {
    const cachedVehicleType = localStorage.getItem(CACHE_KEY_VEHICLE_TYPE);
    if (cachedVehicleType) {
      return cachedVehicleType ? JSON.parse(cachedVehicleType) : null;
    } else {
      return null;
    }
  },

  // Save the default role in the Local Storage
  saveDefaultRole: (role) => {
    try {
      localStorage.setItem(CACHE_KEY_DEFAULT_ROLE, JSON.stringify(role));
    } catch (error) {
      // Handle errors, e.g., when Local Storage is disabled or full.
      console.error('Error saving default role to Local Storage:', error);
    }
  },

  // Retrieve the default role from the Local Storage
  getDefaultRole: () => {
    const cachedRole = localStorage.getItem(CACHE_KEY_DEFAULT_ROLE);
    if (cachedRole) {
      return cachedRole ? JSON.parse(cachedRole) : null;
    } else {
      return null;
    }
  },

  // Save the driver role in the Local Storage
  saveDriverRole: (role) => {
    try {
      localStorage.setItem(CACHE_KEY_DRIVER_ROLE, JSON.stringify(role));
    } catch (error) {
      // Handle errors, e.g., when Local Storage is disabled or full.
      console.error('Error saving driver role to Local Storage:', error);
    }
  },

  // Retrieve the driver role from the Local Storage
  getDriverRole: () => {
    const cachedRole = localStorage.getItem(CACHE_KEY_DRIVER_ROLE);
    if (cachedRole) {
      return cachedRole ? JSON.parse(cachedRole) : null;
    } else {
      return null;
    }
  },

  // Save the customer role in the Local Storage
  saveCustomerRole: (role) => {
    try {
      localStorage.setItem(CACHE_KEY_CUSTOMER_ROLE, JSON.stringify(role));
    } catch (error) {
      // Handle errors, e.g., when Local Storage is disabled or full.
      console.error('Error saving customer role to Local Storage:', error);
    }
  },

  // Retrieve the customer role from the Local Storage
  getCustomerRole: () => {
    const cachedRole = localStorage.getItem(CACHE_KEY_CUSTOMER_ROLE);
    if (cachedRole) {
      return cachedRole ? JSON.parse(cachedRole) : null;
    } else {
      return null;
    }
  },

  // Save the supplier role in the Local Storage
  saveSupplierRole: (role) => {
    try {
      localStorage.setItem(CACHE_KEY_SUPPLIER_ROLE, JSON.stringify(role));
    } catch (error) {
      // Handle errors, e.g., when Local Storage is disabled or full.
      console.error('Error saving supplier role to Local Storage:', error);
    }
  },

  // Retrieve the supplier role from the Local Storage
  getSupplierRole: () => {
    const cachedRole = localStorage.getItem(CACHE_KEY_SUPPLIER_ROLE);
    if (cachedRole) {
      return cachedRole ? JSON.parse(cachedRole) : null;
    } else {
      return null;
    }
  },

  // Save the default starter kit category in the Local Storage
  saveDefaultCategory: (category) => {
    try {
      localStorage.setItem(CACHE_KEY_CATEGORY, JSON.stringify(category));
    } catch (error) {
      // Handle errors, e.g., when Local Storage is disabled or full.
      console.error('Error saving default category to Local Storage:', error);
    }
  },

  // Retrieve the default category from the Local Storage
  getDefaultCategory: () => {
    const cachedCategory = localStorage.getItem(CACHE_KEY_CATEGORY);
    if (cachedCategory) {
      return cachedCategory ? JSON.parse(cachedCategory) : null;
    }
    return null;
  },
};

export default CacheService;
