import i18next from 'i18next';
import { lazy } from 'react';

import ar from './i18n/ar';
import de from './i18n/de';
import en from './i18n/en';

i18next.addResourceBundle('en', 'suppliersPage', en);
i18next.addResourceBundle('de', 'suppliersPage', de);
i18next.addResourceBundle('ar', 'suppliersPage', ar);

// Declare a lazy-loaded component Suppliers, which will be dynamically loaded when needed
const Suppliers = lazy(() => import('./list/Suppliers'));
const Supplier = lazy(() => import('./add-edit/Supplier'));

// SuppliersConfig object containing the configuration for the Suppliers page
const SuppliersConfig = {
  // settings object for additional page settings (e.g., layout settings)
  settings: {
    layout: {}, // You can add layout settings here if needed
  },

  // routes array containing the route configurations for the Suppliers page
  routes: [
    {
      path: '/suppliers',
      element: <Suppliers />, // The React element to be rendered for the Suppliers page
    },
    {
      path: '/suppliers/:supplierId/*',
      element: <Supplier />,
    },
  ],
};

export default SuppliersConfig;
