const jwtServiceConfig = {
  login: '/auth/login',
  signIn: 'api/auth/sign-in',
  logout: '/logout',
  signUp: 'api/auth/sign-up',
  accessToken: 'api/auth/access-token',
  userAccessToken: '/users/token',
  updateUser: 'api/auth/user/update',
};

export default jwtServiceConfig;
