import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import TripAvatar from '../../TripAvatar';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Box } from '@mui/system';
import { DATE_TIME_FORMAT } from 'src/app/main/utils/Constants';

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  '&.active': {
    backgroundColor: theme.palette.background.default,
  },
}));

function TripListItem(props) {
  const { trip } = props;
  const routeParams = useParams();

  return (
    <StyledListItem
      button
      className="px-14 py-8 min-h-60"
      active={routeParams.id === trip.id ? 1 : 0}
      component={NavLinkAdapter}
      to={`/map/${trip.id}`}
      end
      activeClassName="active"
    >
      {/* <TripAvatar trip={trip} /> */}
      <div className="flex flex-col">
        <ListItemText
          classes={{
            root: 'min-w-px px-8',
            primary: 'font-medium text-14',
            secondary: 'truncate',
          }}
          primary={trip?.vehicles ? trip?.vehicles[0]?.vin : ''}
          secondary={trip?.status}
        />
        <div className="flex items-center justify-center">
          <FuseSvgIcon size={20} color="disabled">
            heroicons-solid:location-marker
          </FuseSvgIcon>
          <ListItemText
            classes={{
              root: 'min-w-px px-8',
              multiline: true,
              primary: 'font-medium text-14',
              secondary: 'truncate',
            }}
            primary={trip.start_location}
            secondary={
              trip?.start_date
                ? format(new Date(trip?.start_date), DATE_TIME_FORMAT)
                : ''
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <FuseSvgIcon size={20} color="disabled">
            heroicons-solid:location-marker
          </FuseSvgIcon>
          <ListItemText
            classes={{
              root: 'min-w-px px-8',
              multiline: true,
              primary: 'font-medium text-14',
              secondary: 'truncate',
            }}
            primary={trip.end_location}
            secondary={
              trip?.end_date
                ? format(new Date(trip?.end_date), DATE_TIME_FORMAT)
                : ''
            }
          />
        </div>
      </div>
    </StyledListItem>
  );
}

export default TripListItem;
