import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import {
  DEFAULT_VEHICLE_TYPE,
  sortingOptions,
} from 'src/app/main/utils/Constants';
import {
  setDefaultCountry,
  setDefaultDriver,
  setDefaultRole,
} from 'src/app/main/utils/SettingDefaluts';
import CacheService from 'src/app/services/CacheService';

// Async thunk function to fetch trips data from an API
export const getTrips = createAsyncThunk(
  'trips/getTrips',
  async (request, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/trips/search', request);
      if (response.status === 200 && response.data.status === 200) {
        dispatch(setCount(response.data.result.count));
        return response.data.result.rows?.rows;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getTrips failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch countries data from an API
export const getCountries = createAsyncThunk(
  'trips/getCountries',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/countries');
      if (response.status === 200 && response.data.status === 200) {
        const countries = response.data.result;
        setDefaultCountry(countries);
        return countries;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getCountries failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch roles data from an API
export const getRoles = createAsyncThunk(
  'drivers/getRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/roles');
      if (response.status === 200 && response.data.status === 200) {
        const roles = response.data.result;
        setDefaultRole(roles);
        setDefaultDriver(roles);
        return roles;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getRoles failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

export const getDrivers = createAsyncThunk(
  'trips/getDrivers',
  async (request, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/users/search', request);
      if (response.status === 200 && response.data.status === 200) {
        return response.data.result.rows;
      } else {
        return rejectWithValue('Invalid response data');
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getDrivers failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch types data from an API
export const getVehicleTypes = createAsyncThunk(
  'trips/getVehicleTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('vehicles/types');
      if (response.status === 200 && response.data.status === 200) {
        const types = response.data.result.rows;

        // Save default vehicle type to cache
        CacheService.saveVehicleType(
          types.find((type) => type.slug === DEFAULT_VEHICLE_TYPE) || null
        );

        return types;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getVehicleTypes failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

export const getSelectedVehicleType = createAsyncThunk(
  'trips/getSelectedVehicleType',
  async (data, { rejectWithValue }) => {
    try {
      const typeData = data.types?.find(
        (type) => type.id === data.selectedVehicleTypeId
      );
      return typeData;
    } catch (error) {
      // Handle the Axios error here
      console.error('getSelectedVehicleType failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const getVehicles = createAsyncThunk(
  'trips/getVehicles',
  async (request, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/vehicles/search', request);
      if (response.status === 200 && response.data.status === 200) {
        return response.data.result.rows;
      } else {
        return rejectWithValue('Invalid response data');
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getVehicles failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch trip types data from an API
export const getTripTypes = createAsyncThunk(
  'vehicles/getTripTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('trips/types');
      if (response.status === 200 && response.data.status === 200) {
        const types = response.data.result;

        // // Save default trip type to cache
        // CacheService.saveTripType(
        //   difficulties.find((type) => type.slug === DEFAULT_TRIP_TYPE) || null
        // );

        return types;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getTripTypes failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch trip difficulties data from an API
export const getDifficulties = createAsyncThunk(
  'trips/getDifficulties',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('trips/dificulties');
      if (response.status === 200 && response.data.status === 200) {
        const difficulties = response.data.result;

        // // Save default trip difficulties to cache
        // CacheService.saveTripDifficulties(
        //   difficulties.find((type) => type.slug === DEFAULT_TRIP_DIFFICULTY) || null
        // );

        return difficulties;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getDifficulties failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch loadList data from an API
export const getLoadLists = createAsyncThunk(
  'trips/getLoadLists',
  async (request, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/shipments/list', request);
      if (response.status === 200 && response.data.status === 200) {
        return response.data.result.rows;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getLoadLists failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

export const setSelectedLoadLists = createAsyncThunk(
  'trips/setSelectedLoadLists',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      return data;
    } catch (error) {
      return rejectWithValue([]);
    }
  }
);

export const reorderLoadLists = createAsyncThunk(
  'trips/reorderLoadLists',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        '/shipments/updateSortOrder',
        data?.request
      );
      if (response.status === 200 && response.data.status === 200) {
        return data?.orderedList;
      } else {
        return rejectWithValue(data?.unorderedList);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('reorderLoadLists failed:', error.message);
      return rejectWithValue(data?.unorderedList);
    }
  }
);

// Async thunk function to fetch users data from an API
export const getStarterKits = createAsyncThunk(
  'trips/getStarterKits',
  async (request, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/starterkits/search', request);
      if (response.status === 200 && response.data.status === 200) {
        return response.data.result.rows;
      }
      return rejectWithValue([]);
    } catch (error) {
      // Handle the Axios error here
      console.error('getStarterKits failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch categories data from an API
export const getCategories = createAsyncThunk(
  'trips/getCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/starterkits/categories');
      if (response.status === 200 && response.data.status === 200) {
        const categories = response.data.result;
        // Save default country to cache
        const defaultCategory = categories[0]; // Assuming you want to set the category as default from constants
        CacheService.saveDefaultCategory(defaultCategory || null);
        return categories;
      }
      return rejectWithValue([]);
    } catch (error) {
      // Handle the Axios error here
      console.error('getCategories failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

export const getSelectedTrip = createAsyncThunk(
  'trips/getSelectedTrip',
  async (selectedTripId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`trips/details`, {
        trip_id: selectedTripId,
      });
      return response.data.result;
    } catch (error) {
      // Handle the Axios error here
      console.error('getSelectedTrip failed:', error.message);
      return rejectWithValue('Failed to get selected trip data');
    }
  }
);

// Async thunk function to fetch checkpoints based on a trip ID
export const getCheckPoints = createAsyncThunk(
  'trips/getCheckPoints',
  async (tripId, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/trips/getCheckPoints?trip_id=${tripId}`
      );
      if (response.status === 200 && response.data.status === 200) {
        dispatch(setTripCheckpoints(response.data.result));
        return response.data.result;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getCheckPoints failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to save trip checkpoints
export const saveCheckPoints = createAsyncThunk(
  'trips/saveCheckPoints',
  async (checkpoints, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/trips/saveCheckPoints', {
        checkpoints: checkpoints,
      });

      if (response.status === 200 && response.data.status === 200) {
        // You can dispatch an action or return the data as needed
        console.log('Saved checkpoints:', response.data.result);
        // For example, dispatch(setSavedCheckPoints(response.data.result));
        return response.data.result;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('saveCheckPoints failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to check trip duplication
export const checkTripDuplication = async (request) => {
  try {
    const response = await axios.post('/users/validate_user', request);
    if (response.status === 200 && response.data.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // Handle the Axios error here
    console.error('checkTripDuplication failed:', error.message);
    return false; // You can customize the error handling based on your application requirements
  }
};

// Async thunk function to add a new trip
export const addTrip = createAsyncThunk(
  'trips/addTrip',
  async (newTrip, { dispatch, rejectWithValue }) => {
    try {
      let _newtrip = { ...newTrip };
      delete _newtrip['checkpoints'];
      const response = await axios.post('/trips/create', _newtrip);
      if (response.status === 200 && response.data.status === 200) {
        const data = await response?.data?.result;
        const tripId = data?.id;
        const checkpointsWithTripId = newTrip?.checkpoints.map(
          (checkpoint, index) => ({
            ...checkpoint,
            trip_id: tripId,
            sort_order: index + 1,
          })
        );
        dispatch(saveCheckPoints(checkpointsWithTripId));
        return data;
      }
      return rejectWithValue({});
    } catch (error) {
      // Handle the Axios error here
      console.error('addTrip failed:', error.message);
      return rejectWithValue({});
    }
  }
);

// Async thunk function to update an existing trip
export const updateTrip = createAsyncThunk(
  'trips/updateTrip',
  async (request, { rejectWithValue }) => {
    try {
      const url = request?.isMultiple
        ? `trips/updateTripDetails`
        : `trips/updateTrips`;
      const response = await axios.put(url, request?.data);
      const data = await response.data?.result;
      return data;
    } catch (error) {
      // Handle the Axios error here
      console.error('updateTrip failed:', error.message);
      return rejectWithValue('Failed to update trip');
    }
  }
);

// Async thunk function to changeTripStatus a trip
export const changeTripStatus = createAsyncThunk(
  'trips/changeTripStatus',
  async (trip, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/trips/updateTripStatus`, trip);
      const data = await response.data?.result;
      return data;
    } catch (error) {
      // Handle the Axios error here
      console.error('changeTripStatus failed:', error.message);
      return rejectWithValue('Failed to changeTripStatus');
    }
  }
);

// Async thunk function to fetch the download URL for a document
export const getDocumentDownloadUrl = createAsyncThunk(
  'trips/getDocumentDownloadUrl',
  async (documentId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/documents/s3preview?document_id=${documentId}`
      );
      if (response.status === 200 && response.data.status === 200) {
        return response.data.result;
      } else {
        return rejectWithValue('Invalid response data');
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getDocumentDownloadUrl failed:', error.message);
      return rejectWithValue('Failed to fetch document download URL');
    }
  }
);

export const deleteTrip = createAsyncThunk(
  'trips/deleteTrip',
  async (tripId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/trips/delete?trip_id=${tripId}`);
      const data = await response.data?.result;
      return tripId;
    } catch (error) {
      // Handle the Axios error here
      console.error('deleteTrip failed:', error.message);
      return rejectWithValue('Failed to deleteTrip');
    }
  }
);

const tripAdapter = createEntityAdapter({});

// Export selectors for trips data from the entity adapter
export const { selectAll: selectTrips, selectById: selectTripById } =
  tripAdapter.getSelectors((state) => state.trips.trip);

const tripSlice = createSlice({
  name: 'trips',
  initialState: tripAdapter.getInitialState({
    rowsPerPage: 10,
    count: 0,
    // Add property for date range as an object
    dateRange: {
      start: new Date(),
      end: new Date(),
    },
    sortOption: sortingOptions[0],
    searchText: '', // Initial search text state for filtering trips
    countries: [],
    drivers: [],
    vehicleTypes: [],
    vehicles: [],
    loadLists: [],
    selectedLoadLists: [],
    categories: [],
    starterkits: [],
    types: [], // trip types
    difficulties: [], // trip difficulties
    tripStatus: [],
    confirmationDialogOpen: false,
    deleteDialogOpen: false,
    checkpointDialogOpen: false,
    selectedTripId: null,
    selectedTrip: null,
    selectedTripCheckpoints: [],
    selectedVehicleTypeId: 0,
    selectedVehicleType: null,
    checkpointDialog: {
      type: 'checkpoint',
      props: {
        open: false,
        anchorPosition: { top: 200, left: 400 },
      },
      data: null,
    },
    confirmationDialog: {
      type: 'delete',
      props: {
        open: false,
        anchorPosition: { top: 200, left: 400 },
      },
      data: null,
    },
    deleteDialog: {
      type: 'delete',
      props: {
        open: false,
        anchorPosition: { top: 200, left: 400 },
      },
      data: null,
    },
  }),
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setSortOption: (state, action) => {
      state.sortOption = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    updateCount: (state, action) => {
      state.count = state.count - 1;
    },
    setTripCheckpoints: (state, action) => {
      state.selectedTripCheckpoints = action.payload;
    },
    // Reducer to set the search text for trips filtering
    setTripsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setTripStatus: (state, action) => {
      state.tripStatus = action.payload;
    },
    resetTrip: (state, action) => {
      state.selectedTrip = null;
    },
    openCheckpointDialog: {
      prepare: (start_date, end_date) => {
        const payload = {
          type: 'checkpoint',
          props: {
            open: true,
          },
          data: {
            start: start_date,
            end: end_date,
          },
        };
        return { payload };
      },
      reducer: (state, action) => {
        state.checkpointDialogOpen = true;
        state.checkpointDialog = action.payload;
      },
    },
    openConfirmationDialog: {
      prepare: (trip) => {
        const payload = {
          type: 'status',
          props: {
            open: true,
          },
          data: trip,
        };
        return { payload };
      },
      reducer: (state, action) => {
        state.confirmationDialogOpen = true;
        state.confirmationDialog = action.payload;
      },
    },
    openDeleteDialog: {
      prepare: (trip) => {
        const payload = {
          type: 'delete',
          props: {
            open: true,
          },
          data: trip,
        };
        return { payload };
      },
      reducer: (state, action) => {
        state.deleteDialogOpen = true;
        state.deleteDialog = action.payload;
      },
    },
    closeCheckpointDialog: (state, action) => {
      state.checkpointDialogOpen = false;
      state.checkpointDialog = {
        type: 'checkpoint',
        props: {
          open: false,
          anchorPosition: { top: 200, left: 400 },
        },
        data: null,
      };
    },
    closeConfirmationDialog: (state, action) => {
      state.confirmationDialogOpen = false;
      state.confirmationDialog = {
        type: 'status',
        props: {
          open: false,
          anchorPosition: { top: 200, left: 400 },
        },
        data: null,
      };
    },
    closeDeleteDialog: (state, action) => {
      state.deleteDialogOpen = false;
      state.deleteDialog = {
        type: 'delete',
        props: {
          open: false,
          anchorPosition: { top: 200, left: 400 },
        },
        data: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTrips.fulfilled, tripAdapter.setAll)
      .addCase(getTripTypes.fulfilled, (state, action) => {
        // Assuming that the response data contains an array of trip types
        state.types = action.payload;
      })
      .addCase(getDifficulties.fulfilled, (state, action) => {
        // Assuming that the response data contains an array of trip difficulties
        state.difficulties = action.payload;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        // Assuming that the response data contains an array of countries
        state.countries = action.payload;
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.drivers = action.payload;
      })
      .addCase(getVehicleTypes.fulfilled, (state, action) => {
        // Assuming that the response data contains an array of vehicle types
        state.vehicleTypes = action.payload;
      })
      .addCase(getVehicles.fulfilled, (state, action) => {
        state.vehicles = action.payload;
      })
      .addCase(getSelectedVehicleType.fulfilled, (state, action) => {
        state.selectedVehicleTypeId = action.payload?.id;
        state.selectedVehicleType = action.payload;
      })
      .addCase(getLoadLists.fulfilled, (state, action) => {
        state.loadLists = action.payload;
      })
      .addCase(setSelectedLoadLists.fulfilled, (state, action) => {
        state.selectedLoadLists = action.payload;
      })
      .addCase(reorderLoadLists.fulfilled, (state, action) => {
        state.selectedLoadLists = action.payload;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(getStarterKits.fulfilled, (state, action) => {
        state.starterkits = action.payload;
      })
      .addCase(getSelectedTrip.fulfilled, (state, action) => {
        state.selectedTripId = action.payload.id;
        state.selectedTrip = action.payload;
        tripAdapter.upsertOne(state, action.payload);
      })
      // .addCase(addTrip.fulfilled, tripAdapter.addOne)
      .addCase(addTrip.fulfilled, (state, action) => {
        const newTrip = action.payload;
        // Get the current trips' entities and ids
        const { entities, ids } = state;
        // Add the new trip to the entities object
        const newEntities = { [newTrip.id]: newTrip, ...entities };
        // Add the new trip ID to the beginning of the ids array
        const newIds = [newTrip.id, ...ids];
        // Update the state with the modified entities and ids
        state.entities = newEntities;
        state.ids = newIds;
      })
      .addCase(updateTrip.fulfilled, tripAdapter.upsertOne)
      .addCase(changeTripStatus.fulfilled, tripAdapter.upsertOne)
      .addCase(deleteTrip.fulfilled, (state, action) => {
        tripAdapter.removeOne(state, action.payload);
      });
  },
});

export const {
  setDateRange,
  setRowsPerPage,
  setSortOption,
  setCount,
  updateCount,
  setTripCheckpoints,
  setTripsSearchText,
  openCheckpointDialog,
  openConfirmationDialog,
  openDeleteDialog,
  closeCheckpointDialog,
  closeConfirmationDialog,
  closeDeleteDialog,
  newTrip,
  resetTrip,
  setTripStatus,
} = tripSlice.actions;

// Selector function to get the trips search text from the state
export const selectTripsSearchText = ({ trips }) => trips.trip.searchText;
export const selectTripsSortOption = ({ trips }) => trips.trip.sortOption;
export const selectTripsCount = ({ trips }) => trips.trip.count;

export const selectSelectedTrip = ({ trips }) => trips.trip.selectedTrip;
export const selectTripTypes = ({ trips }) => trips.trip.types;
export const selectTripDifficulties = ({ trips }) => trips.trip.difficulties;
export const selectVehicleTypes = ({ trips }) => trips.trip.vehicleTypes;
export const selectTripsStatus = ({ trips }) => trips.trip.tripStatus;

export const selectedVehicleType = ({ trips }) =>
  trips.trip.selectedVehicleType;

export const selectConfirmationDialog = ({ trips }) =>
  trips.trip.confirmationDialog;
export const selectDeleteDialog = ({ trips }) => trips.trip.deleteDialog;
export const selectDeleteDialogOpen = ({ trips }) =>
  trips.trip.deleteDialogOpen;
export const selectTripCheckpoints = ({ trips }) =>
  trips.trip.selectedTripCheckpoints;

export const selectCheckpointDialog = ({ trips }) =>
  trips.trip.checkpointDialog;
export const selectCheckpointDialogOpen = ({ trips }) =>
  trips.trip.checkpointDialogOpen;

export const selectConfirmationDialogOpen = ({ trips }) =>
  trips.trip.confirmationDialogOpen;
export const selectTrip = ({ trips }) => trips.trip;

export default tripSlice.reducer;
