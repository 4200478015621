// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./print.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./tables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./prism.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Component classes registered by plugins.
 *
 */

.container{
  width: 100%;
}

@media (min-width: 600px){

  .container{
    max-width: 600px;
  }
}

@media (min-width: 960px){

  .container{
    max-width: 960px;
  }
}

@media (min-width: 1280px){

  .container{
    max-width: 1280px;
  }
}

@media (min-width: 1920px){

  .container{
    max-width: 1920px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.muiltr-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
  display: block;
  position: relative;
  overflow-x: hidden;
  min-height: 195px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/app-components.css"],"names":[],"mappings":"AAAA;;;EAGE;;AAQF;EAAA,WAAA;AAAoB;;AAApB;;EAAA;IAAA,gBAAA;EAAoB;AAAA;;AAApB;;EAAA;IAAA,gBAAA;EAAoB;AAAA;;AAApB;;EAAA;IAAA,iBAAA;EAAoB;AAAA;;AAApB;;EAAA;IAAA,iBAAA;EAAoB;AAAA;;AAEpB,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":["/**\n * Component classes registered by plugins.\n *\n */\n\n@import 'print.css';\n\n@import 'tables.css';\n\n@import 'prism.css';\n\n@tailwind components;\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type='number'] {\n  -moz-appearance: textfield;\n}\n\n.muiltr-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {\n  display: block;\n  position: relative;\n  overflow-x: hidden;\n  min-height: 195px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
