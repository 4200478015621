const locale = {
  PAGE_TITLE: 'Users',
  ID: 'id',
  SEARCH_USER: 'Search User',
  ADD: 'Add',
  ADD_USER: 'Add User',
  EDIT: 'Edit',
  EDIT_USER: 'Edit User',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  ACTIVATE_USER: 'Activate User',
  DEACTIVATE_USER: 'Deactivate User',
  LOADING: 'Loading',
  USERNAME: 'Username',
  EMAIL: 'Email Address',
  ROLE: 'Role',
  MOBILE_NUMBER: 'Mobile Number',
  STATUS: 'Status',
  CHANGE_USER_STATUS: 'Change user status',
  ACTIONS: 'Actions',
  ROWS_PER_PAGE: 'Rows per page:',
  OF: 'of',

  PASSWORD: 'Password',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  COUNTRY: 'Country',

  USERNAME_REQUIRED_ERR: 'You must enter a username',
  USERNAME_UNAVAILABLE_MSG: 'Username is not available. Please choose a different one.',
  EMAIL_UNAVAILABLE_MSG: 'Email is already in use. Please use a different one.',

  STATUS_CHANGE_CONFIRMATION_MSG: 'Are a sure you want',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
};

export default locale;
