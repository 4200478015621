import i18next from 'i18next';
import { lazy } from 'react';

import ar from './i18n/ar';
import de from './i18n/de';
import en from './i18n/en';

i18next.addResourceBundle('en', 'tripsPage', en);
i18next.addResourceBundle('de', 'tripsPage', de);
i18next.addResourceBundle('ar', 'tripsPage', ar);

// Declare a lazy-loaded component trips, which will be dynamically loaded when needed
const Trips = lazy(() => import('./list/Trips'));
const Trip = lazy(() => import('./add-edit/Trip'));

// TripsConfig object containing the configuration for the Trips page
const TripsConfig = {
  // settings object for additional page settings (e.g., layout settings)
  settings: {
    layout: {}, // You can add layout settings here if needed
  },

  // routes array containing the route configurations for the Trips page
  routes: [
    {
      path: '/trips',
      element: <Trips />, // The React element to be rendered for the Trips page
    },
    {
      path: 'trips/:tripId/*',
      element: <Trip />,
    },
  ],
};

export default TripsConfig;
