import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showPleaseWait } from 'src/app/services/commonService';
import { MapContext } from '../Map';
import TripAvatar from '../TripAvatar';
import {
  getCheckPoints,
  getTrip,
  selectTrip,
  selectTripById,
  setTripCheckpoints,
} from '../store/mapSlice';
import MapView from './MapView';
import TripMoreMenu from './TripMoreMenu';

function MapContent(props) {
  const { setMainSidebarOpen, setTripSidebarOpen } = useContext(MapContext);
  const dispatch = useDispatch();
  const trip = useSelector(selectTrip);
  const routeParams = useParams();
  const tripId = routeParams.id;
  const selectedTrip = useSelector((state) => selectTripById(state, tripId));

  useEffect(() => {
    showPleaseWait();
    dispatch(getTrip(tripId));
  }, [tripId, dispatch]);

  if (!trip || !selectedTrip) {
    // return null;
  }

  useEffect(() => {
    if (!trip) {
      dispatch(setTripCheckpoints([]));
    } else {
      // Call the async thunk to fetch checkpoints
      dispatch(getCheckPoints(trip?.id));
    }
  }, [trip]);

  return (
    <>
      <Box
        className="w-full border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <Toolbar className="flex items-center justify-between px-16 w-full">
          <div className="flex items-center">
            <IconButton
              aria-label="Open drawer"
              onClick={() => setMainSidebarOpen(true)}
              className="flex lg:hidden"
              size="large"
            >
              <FuseSvgIcon>heroicons-outline:map</FuseSvgIcon>
            </IconButton>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                setTripSidebarOpen(true);
              }}
              onKeyDown={() => setTripSidebarOpen(true)}
              role="button"
              tabIndex={0}
            >
              <TripAvatar className="relative mx-8" trip={trip} />
              <Typography
                color="inherit"
                className="text-16 font-semibold px-4"
              >
                {trip?.vehicles ? trip?.vehicles[0]?.vin : ''}
              </Typography>
            </div>
          </div>
          <TripMoreMenu className="-mx-8" />
        </Toolbar>
      </Box>
      <MapView />
    </>
  );
}

export default MapContent;
