import i18next from 'i18next';
import { lazy } from 'react';

import ar from './i18n/ar';
import de from './i18n/de';
import en from './i18n/en';

i18next.addResourceBundle('en', 'usersPage', en);
i18next.addResourceBundle('de', 'usersPage', de);
i18next.addResourceBundle('ar', 'usersPage', ar);

// Declare a lazy-loaded component Users, which will be dynamically loaded when needed
const Users = lazy(() => import('./list/Users'));

// UsersConfig object containing the configuration for the Users page
const UsersConfig = {
    // settings object for additional page settings (e.g., layout settings)
    settings: {
        layout: {}, // You can add layout settings here if needed
    },

    // routes array containing the route configurations for the Users page
    routes: [
        {
            path: '/users',
            element: <Users />, // The React element to be rendered for the Users page
        },
    ],
};

export default UsersConfig;
