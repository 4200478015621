import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { changeUserTheme } from 'app/store/fuse/settingsSlice';
import { selectUser } from 'app/store/userSlice';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function ThemeMode(props) {
    const user = useSelector(selectUser);
    const [isDark, setIsDark] = useState(false); // TODO - based on the user theme 
    const dispatch = useDispatch();

    const toggleTheme = () => {
        setIsDark((prevState) => !prevState);
    }

    useEffect(() => {
        dispatch(changeUserTheme(isDark));
        return () => {
        }
    }, [isDark])

    return (
        <Tooltip title={!isDark ? 'Dark Mode' : 'Light Mode'} placement="bottom">
            <IconButton
                onClick={toggleTheme}
                className={clsx('w-40 h-40', props.className)}
                size="large"
            >
                <FuseSvgIcon>{!isDark ? 'heroicons-outline:moon' : 'heroicons-outline:sun'}</FuseSvgIcon>
            </IconButton>
        </Tooltip>
    )
}

export default ThemeMode