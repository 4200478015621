const locale = {
  PAGE_TITLE: 'Trips',
  ADD: 'Add',
  START_DATE: 'Start Date',
  START_LOCATION: 'Start Location',
  END_DATE: 'End Date',
  END_LOCATION: 'End Location',
  LOADLIST: 'Load List',
  DRIVER: 'Drivers',
  VEHICLE: 'Vehicles',
  NO_LOADLIST: 'There are no load list!',
  MORE_INFO: 'More Info',
  BASIC_INFO: 'Basic Info',
  ADDITIONAL_INFO: 'Additional Info',
  LENGTH_IN_KMS: 'Length in Kilometers',
  TYPE: 'Type',
  VEHICLE_TYPE: 'Vehicle Type',
  DIFFICULTY: 'Difficulty',
  TAX_RATE: 'Tax Rate',
  DESIGNATION: 'Designation',
  NOTES: 'Notes',
  DESCRIPTION: 'Description',
  STARTDATE_ENDDATE: 'Start date - End Date',
  STATUS: 'Status',
  CHANGE_TRIP_STATUS: 'Change trip status',
  UPDATE_TRIP_STATUS: 'Update trip status',
  DELETE_TRIP: 'Delete trip',
  DELETE: 'Delete',
  ACTIONS: 'Actions',
  ALL: 'All',
  ROWS_PER_PAGE: 'Rows per page',
  OF: 'of',
  HELPER_TEXT: 'Please select start and end dates first.',
  NOT_STARTED: 'Not Started',
  STARTER_KIT: 'Starter Kit',
  NO_STARTERKITS: 'There are no starter kits!',
  EDIT_TRIP: 'Edit Trip',
  NO_TRIP: 'No Trip',
  GO_TO_TRIPS: 'Go to Trips',
  EDIT: 'Edit',
  SAVE: 'Save',
  ASSIGNED: 'Assigned',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  ONLOAD: 'Onload',
  INPROGRESS: 'In Progress',
  DESTINATION_REACHED: 'Destination Reached',
  OFFLOAD: 'Offload',
  HOLD: 'Hold',
  COMPLETED: 'Completed',
  CONFIRMATION_MESSAGE: 'Are you sure you want to delete trip?',
  CHECKPOINTS: 'Checkpoints',
};

export default locale;
