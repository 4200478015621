import React from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import TextField from '@mui/material/TextField';

const EditableField = ({
  field,
  type,
  focusedField,
  errors,
  required,
  isEdit,
  label,
  setFocusedField,
  handleEditField,
  handleCancelEdit,
  handleEnterKeyPress,
}) => {
  const isNumberType = type === 'number';

  const handleInputChange = (event) => {
    if (isNumberType) {
      // Allow only numeric input for number type fields
      const numericValue = event.target.value.replace(/[^0-9.]/g, ''); // Allowing decimal points as well
      field.onChange(numericValue);
    } else {
      field.onChange(event.target.value);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <TextField
          {...field}
          size="small"
          className={
            focusedField === field.name || errors?.[field.name] ? '' : 'mb-16'
          }
          label={label}
          required={required}
          onFocus={() => setFocusedField(field.name)}
          type={isNumberType ? 'number' : 'text'} // Set the type as 'number' for number fields
          error={!!errors?.[field.name]}
          variant="outlined"
          autoComplete="off"
          fullWidth
          onChange={handleInputChange}
          onKeyDown={(event) => {
            setFocusedField(field.name);
            handleEnterKeyPress(event, field.name, field.value);
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-between">
        <div>
          {errors?.[field.name] && (
            <div className="flex items-center text-sm text-red">
              <span className="p-4">{errors[field.name].message}</span>
            </div>
          )}
        </div>
        {focusedField === field.name && isEdit ? (
          <div className="flex">
            <div className="border p-1 rounded">
              <FuseSvgIcon
                className="cursor-pointer text-gray"
                onClick={() => {
                  handleEditField(field.name, field.value);
                }}
              >
                heroicons-outline:check
              </FuseSvgIcon>
            </div>
            <div className="border p-1 rounded ml-2">
              <FuseSvgIcon
                className="cursor-pointer text-gray"
                onClick={() => {
                  handleCancelEdit(field.name);
                }}
              >
                heroicons-outline:x
              </FuseSvgIcon>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditableField;
