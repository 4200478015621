import i18next from 'i18next';
import { lazy } from 'react';

import ar from './i18n/ar';
import de from './i18n/de';
import en from './i18n/en';

i18next.addResourceBundle('en', 'starterKitPage', en);
i18next.addResourceBundle('de', 'starterKitPage', de);
i18next.addResourceBundle('ar', 'starterKitPage', ar);

// Declare a lazy-loaded component StarterKit, which will be dynamically loaded when needed
const StarterKit = lazy(() => import('./list/StarterKit'));

// StarterKitConfig object containing the configuration for the StarterKit page
const StarterKitConfig = {
  // settings object for additional page settings (e.g., layout settings)
  settings: {
    layout: {}, // You can add layout settings here if needed
  },

  // routes array containing the route configurations for the Users page
  routes: [
    {
      path: '/starter-kit',
      element: <StarterKit />, // The React element to be rendered for the StarterKit page
    },
  ],
};

export default StarterKitConfig;
