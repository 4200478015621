import { useEffect, useRef, useState } from 'react';

/**
 * A custom hook to observe an element's intersection with the viewport.
 * @param {function} callback - A callback function to be invoked when the element intersects the viewport.
 * @param {Object} options - Intersection Observer options.
 * @returns {Object} - An object containing the ref for the target element and a boolean value indicating whether it's intersecting.
 */
function useIntersectionObserver(callback, options = {}) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsIntersecting(true);
          callback();
        }
      },
      options
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [callback, options]);

  return { targetRef, isIntersecting, setIsIntersecting };
}

export default useIntersectionObserver;
