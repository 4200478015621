import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import format from 'date-fns/format';
import { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DATE_TIME_FORMAT } from 'src/app/main/utils/Constants';
import { MapContext } from '../../Map';
import {
  selectTripCheckpoints,
  setTripCheckpoints,
} from '../../store/mapSlice';

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  '&.active': {
    backgroundColor: theme.palette.background.default,
  },
}));

const RouteTab = ({ trip }) => {
  const dispatch = useDispatch();

  const { setCheckpointSidebarOpen } = useContext(MapContext);
  const { t } = useTranslation('mapPage');
  const checkpoints = useSelector(selectTripCheckpoints);
  const [routeInfo, setRouteInfo] = useState(checkpoints);

  if (!trip) {
    return null;
  }

  useEffect(() => {
    setRouteInfo(checkpoints);
  }, [checkpoints]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    const ordered = _.merge([], routeInfo);

    const [removed] = ordered.splice(startIndex, 1);

    ordered.splice(endIndex, 0, removed);
    setRouteInfo(ordered);
    dispatch(setTripCheckpoints(ordered));
  }

  return (
    <>
      <div className="flex items-end justify-end w-full py-4">
        {/* <Button
          className=" px-8"
          size="small"
          onClick={() => {
            setCheckpointSidebarOpen(true);
          }}
          variant="contained"
          color="secondary"
          startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
        >
          {t('ADD')}
        </Button> */}
      </div>
      <List className="w-full m-0 p-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list" type="list" direction="vertical">
            {(provided) => (
              <>
                <div ref={provided.innerRef}>
                  {routeInfo.map((checkpoint, index) => {
                    const isPackage = checkpoint.type === 'package';
                    const isBreak = checkpoint.type === 'break';
                    let icon = isBreak
                      ? 'heroicons-solid:stop'
                      : 'heroicons-solid:cube';

                    if (isPackage) {
                      if (index === 0 || index === routeInfo.length - 1) {
                        icon = 'heroicons-solid:location-marker';
                      }
                    }

                    return (
                      <Draggable
                        key={String(index)}
                        draggableId={String(index)}
                        isDragDisabled={!isBreak}
                        index={index}
                        type="list"
                      >
                        {(provided, snapshot) => (
                          <>
                            <ListItem
                              className={clsx(
                                snapshot.isDragging ? 'shadow-lg' : '',
                                'px-24 py-6 group'
                              )}
                              sx={{ bgcolor: 'background.paper' }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className="md:hidden absolute flex items-center justify-center inset-y-0 left-0 w-16 cursor-move md:group-hover:flex"
                                {...provided.dragHandleProps}
                              >
                                <FuseSvgIcon
                                  sx={{ color: 'text.disabled' }}
                                  size={20}
                                >
                                  heroicons-solid:menu
                                </FuseSvgIcon>
                              </div>
                              <FuseSvgIcon size={20} color="disabled">
                                {icon}
                              </FuseSvgIcon>
                              <ListItemText
                                classes={{
                                  root: 'min-w-px px-8',
                                  multiline: true,
                                  primary: 'font-medium text-14',
                                  secondary: 'truncate',
                                }}
                                primary={checkpoint?.location}
                                secondary={
                                  checkpoint.eventDate
                                    ? format(
                                        new Date(checkpoint.eventDate),
                                        DATE_TIME_FORMAT
                                      )
                                    : ''
                                }
                              />
                            </ListItem>
                          </>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </>
  );
};

export default RouteTab;
