import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';

export const getNotifications = createAsyncThunk(
  'notificationPanel/getData',
  async (request, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/notifications/list', { ...request });
      const data = await response.data.result.rows;
      dispatch(setCount(response.data.result.count));
      return data;
    } catch (error) {
      console.error('updateNotification failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const getTripNotifications = createAsyncThunk(
  'notificationPanel/getTripData',
  async (item) => {
    try {
      const response = await axios.get(`/trips/notifications?user_id=1`);
      const data = await response.data.result.rows;
      return data;
    } catch (error) {
      console.error('updateNotification failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const getNotificationsType = createAsyncThunk(
  'notificationPanel/getTypes',
  async (item) => {
    try {
      const response = await axios.get(`/notifications/notification_types`);
      const data = await response.data.result;
      return data;
    } catch (error) {
      console.error('updateNotification failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const getNotificationsCount = createAsyncThunk(
  'notificationPanel/getCount',
  async (request) => {
    try {
      const response = await axios.post(`/notifications/count`, request);
      const data = await response.data.result;
      return data;
    } catch (error) {
      console.error('updateNotification failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const dismissAll = createAsyncThunk(
  'notificationPanel/dismissAll',
  async () => {
    try {
      const response = await axios.get(`/trips/notifications?user_id=1`);
      const data = await response.data.result.rows;

      return data;
    } catch (error) {
      console.error('updateNotification failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const deleteNotification = createAsyncThunk(
  'notificationPanel/deleteNotification',
  async (request) => {
    try {
      const response = await axios.post(`/notifications/delete`, request);
      await response.data;
      return request.id[0];
    } catch (error) {
      console.error('updateNotification failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const addNotification = createAsyncThunk(
  'notificationPanel/addNotification',
  async (item) => {
    try {
      const response = await axios.post(`/api/notifications`, { ...item });
      const data = await response.data;
      return data;
    } catch (error) {
      console.error('updateNotification failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const updateNotification = createAsyncThunk(
  'notificationPanel/updateNotification',
  async (item) => {
    try {
      const response = await axios.post(`/notifications/update`, { ...item });
      const data = await response.data?.result;
      return data;
    } catch (error) {
      console.error('updateNotification failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const saveToken = createAsyncThunk(
  'notificationPanel/saveDeviceToken',
  async (request) => {
    try {
      const response = await axios.post(`/users/saveDeviceToken`, request);
      await response.data;
      return response.data;
    } catch (error) {
      console.error('saveDeviceToken failed:', error.message);
      return rejectWithValue({});
    }
  }
);

const notificationsAdapter = createEntityAdapter({});

export const {
  selectAll: selectNotifications,
  selectById: selectNotificationsById,
} = notificationsAdapter.getSelectors((state) => state.notificationPanel.data);

const dataSlice = createSlice({
  name: 'notificationPanel/data',
  initialState: notificationsAdapter.getInitialState({
    refetchNotifications: 1,
    count: 0,
  }),
  reducers: {
    setCount: (state, action) => {
      state.count = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getNotifications.fulfilled, notificationsAdapter.setAll)
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.refetchNotifications += 1;
        notificationsAdapter.removeOne(state, action.payload);
      })
      .addCase(dismissAll.fulfilled, (state, action) =>
        notificationsAdapter.removeAll(state)
      )
      .addCase(getTripNotifications.fulfilled, (state, action) =>
        notificationsAdapter.addMany(state, action.payload)
      )
      .addCase(addNotification.fulfilled, (state, action) =>
        notificationsAdapter.addOne(state, action.payload)
      )
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.refetchNotifications += 1;
      });
  },
});

export const { setCount } = dataSlice.actions;

export const selectRefetchNotifications = ({ notificationPanel }) =>
  notificationPanel?.data?.refetchNotifications;
export const selectNotificationCount = ({ notificationPanel }) =>
  notificationPanel?.data?.count;

export default dataSlice.reducer;
