import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import {
  sortingOptions
} from 'src/app/main/utils/Constants';
import { setDefaultCountry, setDefaultRole } from 'src/app/main/utils/SettingDefaluts';
import CacheService from 'src/app/services/CacheService';

// Async thunk function to fetch users data from an API
export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (request, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/users/search', request);
      if (response.status === 200 && response.data.status === 200) {
        dispatch(setCount(response.data.result.count));
        return response.data.result.rows;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getUsers failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch roles data from an API
export const getRoles = createAsyncThunk(
  'users/getRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/roles');
      if (response.status === 200 && response.data.status === 200) {
        const roles = response.data.result;

        setDefaultRole(roles)

      

        return roles;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getRoles failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch countries data from an API
export const getCountries = createAsyncThunk(
  'users/getCountries',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/countries');
      if (response.status === 200 && response.data.status === 200) {
        const countries = response.data.result;
        setDefaultCountry(countries);
        return countries;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getCountries failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

export const getSelectedUser = createAsyncThunk(
  'users/getSelectedUser',
  async (selectedUserId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`users/details`, {
        user_id: selectedUserId,
      });
      return response.data.result;
    } catch (error) {
      // Handle the Axios error here
      console.error('getSelectedUser failed:', error.message);
      return rejectWithValue('Failed to get selected user data');
    }
  }
);

export const getSelectedRole = createAsyncThunk(
  'users/getSelectedRole',
  async (data, { rejectWithValue }) => {
    try {
      const roleData = data.roles?.find(
        (role) => role.id === data.selectedRoleId
      );
      return roleData;
    } catch (error) {
      // Handle the Axios error here
      console.error('getSelectedRole failed:', error.message);
      return rejectWithValue('Failed to get selected role data');
    }
  }
);

// Async thunk function to deactivate users
export const deactivateUsers = createAsyncThunk(
  'users/deactivateUsers',
  async (userIds, { rejectWithValue }) => {
    try {
      await axios.delete('/api/users', { data: userIds });
      return userIds;
    } catch (error) {
      // Handle the Axios error here
      console.error('deactivateUsers failed:', error.message);
      return rejectWithValue('Failed to deactivate users');
    }
  }
);

// Async thunk function to check user duplication
export const checkUserDuplication = async (request) => {
  try {
    const response = await axios.post('/users/validate_user', request);
    if (response.status === 200 && response.data.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // Handle the Axios error here
    console.error('checkUserDuplication failed:', error.message);
    return false; // You can customize the error handling based on your application requirements
  }
};

// Async thunk function to add a new user
export const addUser = createAsyncThunk(
  'users/addUser',
  async (newUser, { rejectWithValue }) => {
    try {
      const response = await axios.post('/users/create', newUser);
      const data = await response?.data?.result;
      return data;
    } catch (error) {
      // Handle the Axios error here
      console.error('addUser failed:', error.message);
      return rejectWithValue('Failed to add user');
    }
  }
);

// Async thunk function to update an existing user
export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.put(`users/update`, user);
      const data = await response.data?.result;
      return data;
    } catch (error) {
      // Handle the Axios error here
      console.error('updateUser failed:', error.message);
      return rejectWithValue('Failed to update user');
    }
  }
);

// Async thunk function to changeUserStatus a user
export const changeUserStatus = createAsyncThunk(
  'users/changeUserStatus',
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/users/update`, user);
      const data = await response.data?.result;
      return data;
    } catch (error) {
      // Handle the Axios error here
      console.error('changeUserStatus failed:', error.message);
      return rejectWithValue('Failed to changeUserStatus');
    }
  }
);

const userAdapter = createEntityAdapter({});

// Export selectors for users data from the entity adapter
export const { selectAll: selectUsers, selectById: selectUserById } =
  userAdapter.getSelectors((state) => state.users.user);

const userSlice = createSlice({
  name: 'user',
  initialState: userAdapter.getInitialState({
    rowsPerPage: 10,
    count: 0,
    sortOption: sortingOptions[0],
    searchText: '', // Initial search text state for filtering users
    roles: [], // user roles
    countries: [],
    userDialogOpen: false,
    confirmationDialogOpen: false,
    selectedUserId: null,
    selectedUser: null,
    selectedRoleId: 0,
    selectedRole: null,
    userDialog: {
      type: 'new',
      props: {
        open: false,
        anchorPosition: { top: 200, left: 400 },
      },
      data: null,
    },
    confirmationDialog: {
      type: 'delete',
      props: {
        open: false,
        anchorPosition: { top: 200, left: 400 },
      },
      data: null,
    },
  }),
  reducers: {
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setSortOption: (state, action) => {
      state.sortOption = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    updateCount: (state, action) => {
      state.count = state.count - 1;
    },
    // Reducer to set the search text for users filtering
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },

    openNewUserDialog: {
      prepare: () => {
        const payload = {
          type: 'new',
          props: {
            open: true,
          },
          data: {
            role_id: CacheService.getDefaultRole()?.id || '', // Set default value for role from CacheService
            country_id: CacheService.getDefaultCountry()?.id || '', // Set default value for country from CacheService
          },
        };
        return { payload };
      },
      reducer: (state, action) => {
        state.userDialogOpen = true;
        state.userDialog = action.payload;
      },
    },
    openEditUserDialog: {
      prepare: (user) => {
        const payload = {
          type: 'edit',
          props: {
            open: true,
          },
          data: user,
        };
        return { payload };
      },
      reducer: (state, action) => {
        state.userDialogOpen = true;
        state.userDialog = action.payload;
      },
    },
    openConfirmationDialog: {
      prepare: (user) => {
        const payload = {
          type: 'status',
          props: {
            open: true,
          },
          data: user,
        };
        return { payload };
      },
      reducer: (state, action) => {
        state.confirmationDialogOpen = true;
        state.confirmationDialog = action.payload;
      },
    },
    closeNewUserDialog: (state, action) => {
      state.userDialogOpen = false;
      state.userDialog = {
        type: 'new',
        props: {
          open: false,
          anchorPosition: { top: 200, left: 400 },
        },
        data: null,
      };
    },
    closeEditUserDialog: (state, action) => {
      state.userDialogOpen = false;
      state.userDialog = {
        type: 'edit',
        props: {
          open: false,
          anchorPosition: { top: 200, left: 400 },
        },
        data: null,
      };
    },
    closeConfirmationDialog: (state, action) => {
      state.confirmationDialogOpen = false;
      state.confirmationDialog = {
        type: 'delete',
        props: {
          open: false,
          anchorPosition: { top: 200, left: 400 },
        },
        data: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsers.fulfilled, userAdapter.setAll)
      .addCase(getRoles.fulfilled, (state, action) => {
        // Assuming that the response data contains an array of user roles
        state.roles = action.payload;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        // Assuming that the response data contains an array of countries
        state.countries = action.payload;
      })
      .addCase(getSelectedUser.fulfilled, (state, action) => {
        state.selectedUserId = action.payload.id;
        state.selectedUser = action.payload;
        userAdapter.upsertOne(state, action.payload);
      })
      .addCase(getSelectedRole.fulfilled, (state, action) => {
        state.selectedRoleId = action.payload?.id;
        state.selectedRole = action.payload;
      })
      .addCase(deactivateUsers.fulfilled, (state, action) =>
        userAdapter.removeMany(state, action.payload)
      )
      // .addCase(addUser.fulfilled, userAdapter.addOne)
      .addCase(addUser.fulfilled, (state, action) => {
        const newUser = action.payload;
        // Get the current users' entities and ids
        const { entities, ids } = state;
        // Add the new user to the entities object
        const newEntities = { [newUser.id]: newUser, ...entities };
        // Add the new user ID to the beginning of the ids array
        const newIds = [newUser.id, ...ids];
        // Update the state with the modified entities and ids
        state.entities = newEntities;
        state.ids = newIds;
      })
      .addCase(updateUser.fulfilled, userAdapter.upsertOne)
      .addCase(changeUserStatus.fulfilled, userAdapter.upsertOne);
  },
});

export const {
  setRowsPerPage,
  setSortOption,
  setCount,
  updateCount,
  setUsersSearchText,
  openNewUserDialog,
  closeNewUserDialog,
  openEditUserDialog,
  closeEditUserDialog,
  openConfirmationDialog,
  closeConfirmationDialog,
} = userSlice.actions;

// Selector function to get the users search text from the state
export const selectUsersSearchText = ({ users }) => users.user?.searchText;
export const selectUsersSortOption = ({ users }) => users.user?.sortOption;
export const selectUsersCount = ({ users }) => users.user?.count;
export const selectRoles = ({ users }) => users.user?.roles;

export const selectSelectedUser = ({ users }) => users.user?.selectedUser;
export const selectedRole = ({ users }) => users.user?.selectedRole;

export const selectUserDialog = ({ users }) => users.user?.userDialog;
export const selectUserDialogOpen = ({ users }) => users.user?.userDialogOpen;

export const selectConfirmationDialog = ({ users }) =>
  users.user?.confirmationDialog;
export const selectConfirmationDialogOpen = ({ users }) =>
  users.user?.confirmationDialogOpen;

export default userSlice.reducer;
