import CacheService from 'src/app/services/CacheService';
import {
  CUSTOMER_ROLE,
  DEFAULT_COUNTRY,
  DEFAULT_ROLE,
  DRIVER_ROLE,
  SUPPLIER_ROLE,
} from './Constants';

export const setDefaultCountry = (countries) => {
  // Save default country to cache
  CacheService.saveDefaultCountry(
    countries.find((country) => country.code === DEFAULT_COUNTRY) || null
  );
};

export const setDefaultRole = (roles) => {
  // Save default role to cache
  CacheService.saveDefaultRole(
    roles.find((role) => role.slug === DEFAULT_ROLE) || null
  );
};

export const setDefaultDriver = (roles) => {
  // Save driver role to cache
  CacheService.saveDriverRole(
    roles.find((role) => role.slug === DRIVER_ROLE) || null
  );
};

export const setDefaultCustomer = (roles) => {
  // Save customer role to cache
  CacheService.saveCustomerRole(
    roles.find((role) => role.slug === CUSTOMER_ROLE) || null
  );
};

export const setDefaultSupplier = (roles) => {
  // Save customer role to cache
  CacheService.saveSupplierRole(
    roles.find((role) => role.slug === SUPPLIER_ROLE) || null
  );
};
