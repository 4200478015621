const locale = {
  PAGE_TITLE: 'Suppliers',
  ID: 'id',
  SEARCH_SUPPLIER: 'Search Supplier',
  ADD: 'Add',
  ADD_SUPPLIER: 'Add Supplier',
  EDIT: 'Edit',
  EDIT_SUPPLIER: 'Edit Supplier',
  DELETE: 'Delete',
  DELETE_SUPPLIER: 'Delete Supplier',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  ACTIVATE_SUPPLIER: 'Activate Supplier',
  DEACTIVATE_SUPPLIER: 'Deactivate Supplier',
  LOADING: 'Loading',
  USERNAME: 'Username',
  EMAIL: 'Email Address',
  ROLE: 'Role',
  MOBILE_NUMBER: 'Mobile Number',
  STATUS: 'Status',
  CHANGE_SUPPLIER_STATUS: 'Change supplier status',
  ACTIONS: 'Actions',
  ROWS_PER_PAGE: 'Rows per page:',
  OF: 'of',
  POSTAL_CODE: 'Postal Code',
  ADDRESS: 'Address',
  CITY: 'City',
  STATE: 'State',
  DISTRICT: 'District',
  NO_SUPPLIER: 'There is no such supplier!',
  GO_TO_SUPPLIER: 'Go to suppliers page',

  BASIC_INFO: 'Basic Info',
  ADDITIONAL_INFO: 'Additional Info',

  PASSWORD: 'Password',
  SALUTATION: 'Salutation',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  COUNTRY: 'Country',
  DESIGNATION: 'Designation',
  COMPANY: 'Company',
  FAX: 'Fax',
  OWN_CUSTOMER_NUMBER: 'Own Customer Number',
  NOTE: 'Note',
  VAT_ID: 'Vat ID',
  TAX_NUMBER: 'Tax Number',
  COMMERCIAL_REGISTER_NUMBER: 'Commercial Register Number',
  MERCHANT_NUMBER: 'Merchant Number',

  USERNAME_REQUIRED_ERR: 'You must enter a username',
  USERNAME_UNAVAILABLE_MSG: 'Username is not available. Please choose a different one.',
  EMAIL_UNAVAILABLE_MSG: 'Email is already in use. Please use a different one.',
  CUSTOMER_DEACTIVATE_MSG:'Shipments are associated with the supplier.',

  STATUS_CHANGE_CONFIRMATION_MSG: 'Are a sure you want',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
};

export default locale;
