import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import de from './navigation-i18n/de';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('de', 'navigation', de);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'dashboard-component',
    title: 'Dashboard',
    translate: 'DaSHBOARD',
    type: 'item',
    icon: 'heroicons-outline:chart-pie',
    url: 'dashboard',
  },
  {
    id: 'drivers-component',
    title: 'Drivers',
    translate: 'DRIVERS',
    type: 'item',
    icon: 'heroicons-outline:user',
    url: 'drivers',
  },

  {
    id: 'clients',
    title: 'Clients',
    type: 'collapse',
    icon: 'heroicons-outline:users',
    translate: 'CLIENTS',
    children: [
      {
        id: 'suppliers',
        title: 'Supplier',
        type: 'item',
        translate: 'SUPPLIERS',
        url: '/suppliers',
      },
      {
        id: 'customers',
        title: 'Customers',
        type: 'item',
        translate: 'CUSTOMERS',
        url: '/customers',
      },
    ],
  },
  {
    id: 'users-component',
    title: 'Users',
    translate: 'USERS',
    type: 'item',
    icon: 'heroicons-outline:user-group',
    url: 'users',
  },
  {
    id: 'roles-component',
    title: 'Roles',
    translate: 'ROLES',
    type: 'item',
    icon: 'heroicons-outline:shield-check',
    url: 'roles',
  },
  {
    id: 'trip_plan',
    title: 'Trip Plan',
    // subtitle: 'Trip planner',
    type: 'group',
    icon: 'heroicons-outline:cube',
    translate: 'TRIP_PLAN',
    children: [
      {
        id: 'trip_plan.overview',
        title: 'Overview',
        type: 'item',
        icon: 'heroicons-outline:view-boards',
        translate: 'OVERVIEW',
        url: 'triphub',
      },
      {
        id: 'trip_plan.map',
        title: 'Map',
        type: 'item',
        icon: 'heroicons-outline:map',
        translate: 'MAP',
        url: 'map',
      },
      {
        id: 'trip_plan.trips',
        title: 'Trips',
        type: 'collapse',
        icon: 'heroicons-outline:truck',
        translate: 'TRIPS',
        children: [
          {
            id: 'trips-component',
            title: 'Scheduled Trips',
            translate: 'SCHEDULED_TRIPS',
            type: 'item',
            url: 'trips',
          },
          {
            id: 'loadlist-component',
            title: 'Load list',
            translate: 'LOADLIST',
            type: 'item',
            // icon: 'material-outline:article',
            url: 'load-list',
          },
          {
            id: 'starter_kit-component',
            title: 'Starter Kit',
            translate: 'STARTER_KIT',
            type: 'item',
            url: 'starter-kit',
          },
          {
            id: 'vehicle-component',
            title: 'Vehicles',
            translate: 'VEHICLES',
            type: 'item',
            // icon: 'material-outline:article',
            url: 'vehicles',
          },
        ],
      },
    ],
  },
];

export default navigationConfig;
