import i18next from 'i18next';
import { lazy } from 'react';
import ar from './i18n/ar';
import de from './i18n/de';
import en from './i18n/en';
import MapSplashScreen from './MapSplashScreen';
import MapContent from './map/MapContent';

i18next.addResourceBundle('en', 'mapPage', en);
i18next.addResourceBundle('de', 'mapPage', de);
i18next.addResourceBundle('ar', 'mapPage', ar);

const Map = lazy(() => import('./Map'));

const MapConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'map',
      element: <Map />,
      children: [
        {
          path: '',
          element: <MapSplashScreen />,
        },
        {
          path: ':id',
          element: <MapContent />,
        },
      ],
    },
  ],
};

export default MapConfig;
