import { lazy } from 'react';

// Declare a lazy-loaded component Roles, which will be dynamically loaded when needed
const Roles = lazy(() => import('./list/Roles'));

// RolesConfig object containing the configuration for the Roles page
const RolesConfig = {
    // settings object for additional page settings (e.g., layout settings)
    settings: {
        layout: {}, // You can add layout settings here if needed
    },

    // routes array containing the route configurations for the Roles page
    routes: [
        {
            path: '/roles', 
            element: <Roles />, // The React element to be rendered for the Roles page
        },
    ],
};

export default RolesConfig;
