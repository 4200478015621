import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err?.response?.status === 401 && err?.config && !err?.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithUsernameAndPassword = (username, password) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    let body = {
      username: username,
      password: password,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.login, body, { headers: headers })
        .then((response) => {
          if (response.data.status === 200) {
            localStorage.setItem('user', JSON.stringify(response?.data?.result?.user));
            this.setSession(response?.data?.result?.user?.access_token);
            resolve(response?.data?.result?.user);
            this.emit('onLogin', response?.data?.result?.user);
          } else {
            reject(response?.data);
          }
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.signIn, {
          data: {
            email: 'admin@fusetheme.com',
            password: 'admin',
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
            this.emit('onLogin', response.data.user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.accessToken, {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  signInWithUserToken = () => {
    return new Promise((resolve, reject) => {
      const headers = new Headers({
        Authorization: null,
      });

      const userDetails =
        localStorage.getItem('user') !== null && JSON.parse(localStorage.getItem('user'));

      if (userDetails) {
        let body = {
          accessToken: this.getAccessToken() || userDetails.access_token,
        };

        axios
          .post(jwtServiceConfig.userAccessToken, body)
          .then((response) => {
            // console.log(response)
            if (response.data.result) {
              localStorage.setItem('user', JSON.stringify(response.data.result));
              this.setSession(response.data.result.access_token);
              resolve(response.data.result);
            } else {
              this.logout();
              reject(new Error('Failed to login with token.'));
            }
          })
          .catch((error) => {
            // console.log(error)
            this.logout();
            reject(new Error('Failed to login with token.'));
          });
      } else {
        this.logout();
        reject(new Error('Failed to login with token.'));
      }
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    localStorage.clear();
    this.emit('onLogout', 'Logged out');

    // Logout api call to clear tokens in DB
    axios.get(jwtServiceConfig.logout)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
      });

  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      localStorage.removeItem('user');
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
