import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import axios from 'axios';
import { sortingOptions } from 'src/app/main/utils/Constants';

// Async thunk function to fetch trips data from an API
export const getTrips = createAsyncThunk(
  'map/getTrips',
  async (request, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/trips/search', request);
      if (response.status === 200 && response.data.status === 200) {
        dispatch(setCount(response.data.result.totalCount));
        return response.data.result.rows?.rows;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getTrips failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

export const getTrip = createAsyncThunk(
  'map/trip/getTrip',
  async (tripId, { dispatch, getState }) => {
    try {
      const response = await axios.post(`/trips/details`, { trip_id: tripId });
      if (response.status === 200 && response.data.status === 200) {
        return response.data.result;
      } else {
        return rejectWithValue({});
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getTrip failed:', error.message);
      return rejectWithValue({});
    }
  }
);

export const getVehicleLocationHistory = createAsyncThunk(
  'map/vehicleLocationHistory/get',
  async ({ tripId, vehicleId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/trips/vehicle/location?trip_id=${tripId}&vehicle_id=${vehicleId}`
      );
      if (response.status === 200 && response.data.status === 200) {
        return response.data.result;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getVehicleLocationHistory failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

// Async thunk function to fetch checkpoints based on a trip ID
export const getCheckPoints = createAsyncThunk(
  'map/getCheckPoints',
  async (tripId, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/trips/getCheckPoints?trip_id=${tripId}`
      );
      if (response.status === 200 && response.data.status === 200) {
        dispatch(setTripCheckpoints(response.data.result));
        return response.data.result;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      // Handle the Axios error here
      console.error('getCheckPoints failed:', error.message);
      return rejectWithValue([]);
    }
  }
);

const mapAdapter = createEntityAdapter({});

export const { selectAll: selectTrips, selectById: selectTripById } =
  mapAdapter.getSelectors((state) => state.map.trips);

const mapSlice = createSlice({
  name: 'map/trips',
  initialState: mapAdapter.getInitialState({
    tripId: null,
    trip: null,
    rowsPerPage: 10,
    count: 0,
    sortOption: sortingOptions[0],
    searchText: '', // Initial search text state for filtering trips
    selectedTripCheckpoints: [],
  }),
  reducers: {
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setSortOption: (state, action) => {
      state.sortOption = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setTripCheckpoints: (state, action) => {
      state.selectedTripCheckpoints = action.payload;
    },
    updateCount: (state, action) => {
      state.count = state.count - 1;
    },
    // Reducer to set the search text for trips filtering
    setTripsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers(builder) {
    builder
      // (getTrip.fulfilled]: (state, action) => action.payload,
      .addCase(getTrips.fulfilled, (state, action) => {
        // Append the new trips to the existing ones
        mapAdapter.upsertMany(state, action.payload);
      })

      .addCase(getTrip.fulfilled, (state, action) => {
        state.tripId = action.payload.id;
        state.trip = action.payload;
        mapAdapter.upsertOne(state, action.payload);
      });
  },
});

export const {
  setRowsPerPage,
  setSortOption,
  setCount,
  updateCount,
  setTripCheckpoints,
  setTripsSearchText,
} = mapSlice.actions;

// Selector function to get the trips search text from the state
export const selectTripsSearchText = ({ map }) => map.trips.searchText;
export const selectTripsSortOption = ({ map }) => map.trips.sortOption;
export const selectTripsCount = ({ map }) => map.trips.count;
export const selectTripCheckpoints = ({ map }) =>
  map.trips.selectedTripCheckpoints;

export const selectTrip = ({ map }) => map.trips.trip;

export default mapSlice.reducer;
