import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import { forwardRef, useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectSelectedTrip } from '../main/pages/trips/store/tripSlice';
import { searchLocations } from '../services/TomTomApiService';
import { getLocationDisplayValue } from '../services/commonService';

// This component is used for trip start and end locations (temporarily for checkpoint location)
const LocationSelect = forwardRef((props, ref) => {
  const {
    fieldName,
    label,
    isFocused,
    isEdit,
    isReadOnly,
    trip = null,
    isCheckpoint,
    value,
    onChange,
    className,
    setFocusedField,
    handleEditField,
    handleCancelEdit,
  } = props;

  const methods = useFormContext();
  // Ensure methods and setValue are defined before attempting to destructure
  const { setValue } = methods || {};

  // Get the locations array from the state
  const [locations, setLocations] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Define the fetchLocations function
  const fetchLocations = (inputValue) => {
    if (inputValue) {
      searchLocations(inputValue)
        .then((data) => {
          const fetchedLocations = data.results || [];
          if (isEdit && trip) {
            const location = fetchedLocations?.find(
              (location) =>
                location?.id === trip?.start_location_map_id ||
                location?.id === trip?.end_location_map_id
            );
            if (location) {
              if (fieldName === 'start_location') {
                setValue('start_location', location);
              } else if (fieldName === 'end_location') {
                setValue('end_location', location);
              }
            }
          }
          setLocations(fetchedLocations);
        })
        .catch((error) => {
          console.error('Error fetching locations:', error);
        });
    } else {
      // Clear the locations when the inputValue is empty
      setLocations([]);
    }
  };

  // Fetch locations on component mount
  useEffect(() => {
    if (isEdit && trip && typeof value === 'string') {
      fetchLocations(value);
    }
  }, [isEdit, trip, value]);

  useEffect(() => {
    fetchLocations(inputValue);
  }, [inputValue]);

  return (
    <div className="flex flex-col">
      <Autocomplete
        size="small"
        key={fieldName}
        options={locations} // Use the fetched locations as options
        value={value}
        readOnly={isReadOnly}
        getOptionLabel={(option) => getLocationDisplayValue(option)} // Customize the display value
        isOptionEqualToValue={(option, value) =>
          option && value ? option?.id === value?.id : false
        } // Customize the equality check
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required
            onChange={(e) => setInputValue(e.target.value)}
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.id}>
              {getLocationDisplayValue(option)}
            </li>
          );
        }}
        onChange={(_, newValue) => onChange(newValue)}
        onFocus={() => (!isReadOnly ? setFocusedField(fieldName) : null)}
      />
      {isFocused && isEdit ? (
        <div className="flex justify-end mt-2">
          <div className="border p-1 rounded">
            <FuseSvgIcon
              className="cursor-pointer text-gray-500"
              onClick={() => {
                handleEditField(fieldName, value);
              }}
            >
              heroicons-outline:check
            </FuseSvgIcon>
          </div>
          <div className="border rounded ml-2">
            <FuseSvgIcon
              className="cursor-pointer text-gray-500"
              onClick={() => {
                handleCancelEdit(fieldName);
              }}
            >
              heroicons-outline:x
            </FuseSvgIcon>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default LocationSelect;
